import React, { SVGAttributes, SVGProps } from 'react';

const HomeIcon: React.FC<SVGAttributes<HTMLOrSVGElement>> = ({ fill, ...props })=> {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 30 25" fill="none">
      <path d="M29 23.0001H27V11.4438C27 11.167 26.9426 10.8932 26.8314 10.6398C26.7201 10.3863 26.5575 10.1587 26.3538 9.97132L16.3538 0.536321C16.3489 0.532057 16.3443 0.527462 16.34 0.522571C15.9718 0.187738 15.492 0.00219727 14.9944 0.00219727C14.4967 0.00219727 14.0169 0.187738 13.6488 0.522571L13.635 0.536321L3.64625 9.97132C3.4425 10.1587 3.27985 10.3863 3.16861 10.6398C3.05737 10.8932 2.99996 11.167 3 11.4438V23.0001H1C0.734784 23.0001 0.48043 23.1054 0.292893 23.293C0.105357 23.4805 0 23.7349 0 24.0001C0 24.2653 0.105357 24.5196 0.292893 24.7072C0.48043 24.8947 0.734784 25.0001 1 25.0001H29C29.2652 25.0001 29.5196 24.8947 29.7071 24.7072C29.8946 24.5196 30 24.2653 30 24.0001C30 23.7349 29.8946 23.4805 29.7071 23.293C29.5196 23.1054 29.2652 23.0001 29 23.0001ZM5 11.4438L5.01375 11.4313L15 2.00007L24.9875 11.4288L25.0012 11.4413V23.0001H19V17.0001C19 16.4696 18.7893 15.9609 18.4142 15.5859C18.0391 15.2108 17.5304 15.0001 17 15.0001H13C12.4696 15.0001 11.9609 15.2108 11.5858 15.5859C11.2107 15.9609 11 16.4696 11 17.0001V23.0001H5V11.4438ZM17 23.0001H13V17.0001H17V23.0001Z" fill="#050505"/>
    </svg>
  );
}

export default HomeIcon;