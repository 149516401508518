import axios from "axios"

export interface ICountUserBody {
  startDate: string
  endDate: string
}

export const countNewUserByDate = (body: ICountUserBody) => {
  return axios
    .post<never, number>(
      `${process.env.REACT_APP_OBRANDING_API_URL}/count-user/count-new-user-by-date-om`,
      body
    )
    .then((res: any) => res?.data?.total)
}

export const countTotalVisitor = (body: ICountUserBody) => {
  return axios
    .post<never, number>(
      `${process.env.REACT_APP_OBRANDING_API_URL}/count-user/count-total-visitors-om`,
      body
    )
    .then((res: any) => res?.data?.total)
}

export const countTotalUserByDate = (body: ICountUserBody) => {
  return axios
    .post<never, number>(
      `${process.env.REACT_APP_OBRANDING_API_URL}/count-user/count-total-user-by-date-om`,
      body
    )
    .then((res: any) => res?.data?.total)
}
