export const getAccessToken = () => {
  return localStorage.getItem("accessToken")
}

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken")
}

export const getWalletAddress = () => {
  return localStorage.getItem("walletAddress")
}

export const getAuthorizationHeader = () => {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
  }
}

export const setUserId = (value: number) => {
  return localStorage.setItem("userId", `${value}`)
}

export const setTargetUserId = (value: number) => {
  return localStorage.setItem("target_user_id", `${value}`)
}

export const setShowOnCreated = (value: number) => {
  return localStorage.setItem("showOnCreated", `${value}`)
}

export const getShowOnCreated = () => {
  return localStorage.getItem("showOnCreated") || ""
}

export const getUserId = () => {
  return localStorage.getItem("userId") || ""
}

export const getTargetUserId = () => {
  return localStorage.getItem("target_user_id") || ""
}

export const setUserType = (value: number) => {
  return localStorage.setItem("userType", `${value}`)
}

export const getUserType = () => {
  return localStorage.getItem("userType") || ""
}

export const getIdUserView = () => {
  return localStorage.getItem("idUserView") || 0
}

export const getFirstLogin = () => {
  return localStorage.getItem("firstLogin") || 0
}

export const getPathVerify = () => {
  return localStorage.getItem("pathVerify") || null
}
