import { Button, Modal, Typography } from "antd"
import "./signWallet.scss"
import { useLayoutContext } from "../../../Menu"
import { useState } from "react"
import { InfoCircleOutlined } from "@ant-design/icons"

interface SignWalletModalProps {
	open: boolean
	setOpen: (open: boolean) => void
}
const SignWalletModal = ({ open = false, setOpen }: SignWalletModalProps) => {
	const { signAndAccept } = useLayoutContext()
	const [isShowNote, setIsShowNote] = useState(false)

	const handleCancel = () => {
		setOpen(false)
	}

	const handleOk = () => {
		setIsShowNote(true)
		signAndAccept?.(handleCancel)
	}

	return (
		<>
			<Modal
				title={<></>}
				className="sign-wallet-wrapper"
				visible={open}
				onOk={handleOk}
				footer={
					<>
						<Button className="sign-wallet-btn" onClick={handleCancel}>
							Cancel
						</Button>
						<Button className="sign-wallet-btn btn-active" onClick={handleOk}>
							Accept and sign
						</Button>
					</>
				}
				closable={false}
			>
				<img
					src={require("assets/images/logo-home.png")}
					height={120}
					width={108}
					alt=""
					style={{
						objectFit: "cover",
						textAlign: "center",
					}}
				/>
				<Typography className="sign-wallet-title">
					Welcome to Monbase NFT
				</Typography>

				<Typography className="sign-wallet-content">
					By connecting your wallet and using Monbase NFT, you agree to our{" "}
					<a>Term of Service</a> and <a>Privacy Policy</a>.
				</Typography>

				{isShowNote && (
					<div className="sign-wallet-note">
						<InfoCircleOutlined />
						<Typography className="sign-wallet-note-content">
							Check your wallet for a signature request
						</Typography>
					</div>
				)}
			</Modal>
		</>
	)
}

export default SignWalletModal
