import { useCallback, useEffect, useState } from "react"
import useOPVPriceContract from "./useOPVPriceContract"
import { get } from "@/network/axiosClient"
import { StatusCodeResponse } from "@/network/constants"

const token0 = "0x36C7B164F85D6F775cD128966D5819c7d36FEfF3"
const token1 = "0x55d398326f99059fF775485246999027B3197955"
const OPV_TOKEN = "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865"

const useGetOpvPrice = (): any => {
  const contractMethod = useOPVPriceContract()

  const [data, setData] = useState<any>(0)

  const getData = useCallback(async () => {
    // if (contractMethod) {
    try {
      // const result = await contractMethod?.methods
      //   ?.calculatePriceFromLiquidity(token0, token1, 100, OPV_TOKEN)
      //   .call()

      const result: any = await get("/coins/price/opv-usdt")
      if (result?.status === StatusCodeResponse.SUCCESS) {
        setData(+result?.data?.data[0]?.p)
      }
    } catch (error) {
      console.error("useGetOpvPrice", error)
    }
    // }
  }, [])

  useEffect(() => {
    getData()

    const interval = setInterval(() => {
      getData()
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [getData])

  return [data, contractMethod]
}

export default useGetOpvPrice
