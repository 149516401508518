import { useMemo } from "react"
import HeaderLeft from "./components/HeaderLeft"
import HeaderRight from "./components/HeaderRight"
import "./headerMenu.scss"
import {
  SIDEBAR_WIDTH_DEFAULT,
  SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE,
} from "../../layout/verticalLayout/sideBar"
import GroupCategoriesButton from "./components/buttons/groupCategories"
import { useLocation } from "react-router-dom"

interface IHeaderMenuProps {
  closeNavbar: boolean
  isMobile: boolean
  setCloseNavbar: (closeNavbar: boolean) => void
  isSigned: boolean
}
const HeaderMenu = ({
  closeNavbar,
  isMobile,
  setCloseNavbar,
  isSigned,
}: IHeaderMenuProps) => {
  const { pathname } = useLocation()
  const headerStyled = useMemo(() => {
    if (!isMobile) {
      if (!closeNavbar) {
        return {
          left: `${SIDEBAR_WIDTH_DEFAULT}px`,
          width: `calc(100% - ${SIDEBAR_WIDTH_DEFAULT}px)`,
        }
      }
      return {
        left: `${SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE}px`,
        width: `calc(100% - ${SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE}px)`,
      }
    }
    return {
      left: "0",
      width: "100%",
    }
  }, [closeNavbar, isMobile])

  return (
    <div className="header-wrapper" style={headerStyled}>
      <HeaderLeft />
      <HeaderRight
        setCloseNavbar={setCloseNavbar}
        closeNavbar={closeNavbar}
        isSigned={isSigned}
      />
      {pathname?.includes("reward") ? <></> : <GroupCategoriesButton />}
    </div>
  )
}

export default HeaderMenu
