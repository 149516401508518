import { Contract } from "@ethersproject/contracts"
import { useMemo } from "react"
import useActiveWeb3React from "./useActiveWeb3React"
import { useWeb3React } from "@web3-react/core"

export default function useContract<T extends Contract = Contract>(
  address: string,
  ABI: any
): T | null {
  const { library, account, chainId } = useActiveWeb3React()
  const { provider } = useWeb3React()
  const signer = provider?.getSigner(account)
  return useMemo(() => {
    if (!address || !ABI || !library || !chainId || !account || !provider) {
      return null
    }

    try {
      // return new Contract(address, ABI, library.getSigner(account as string))
      return new Contract(address, ABI, signer ?? provider)
    } catch (error) {
      console.error("Failed To Get Contract", error)

      return null
    }
  }, [address, ABI, library, chainId, account, signer, provider]) as T
}
