import { useMemo } from "react"
import SearchInput from "./inputs/Search"
import { Button, Dropdown, Grid, Menu } from "antd"
import { routes } from "@/router/constants"
import { useHistory } from "react-router-dom"
const { useBreakpoint } = Grid

interface IHeaderLeftProps {}

const HeaderLeft = ({}: IHeaderLeftProps) => {
  const history = useHistory()
  const breakPoint = useBreakpoint()
  const groupButton = [
    {
      title: "Explore",
      path: routes.EXPLORE_NFT,
      type: "button",
      className: "btn-menu",
    },
    {
      title: "Stats",
      path: "/",
      type: "button",
      className: "btn-menu",
      submenu: [
        { title: "Rakings", key: "1", path: "/ranking" },
        { title: "Activities", key: "2", path: "/activities" },
      ],
    },
  ]

  const iconLogo = useMemo(() => {
    return (
      <img
        src={require("@/assets/images/new-design/logo-header1.png")}
        alt=""
        className="anticon-logo"
        onClick={() => handleChangePage(routes.HOMEPAGE)}
      />
    )
  }, [])

  const renderGroupButton = useMemo(() => {
    return (
      <>
        {iconLogo}
        <div className="group-btn">
          {groupButton.map((_button) => {
            if (_button?.type === "img") {
              return (
                <img
                  key={_button?.title ?? ""}
                  className={_button?.className ?? ""}
                  src={require("@/assets/images/new-design/logo-header1.png")}
                  alt=""
                />
              )
            }
            if (_button?.submenu && _button?.submenu?.length > 0) {
              const menuCreate = (
                <Menu>
                  {_button?.submenu.map((_submenu) => {
                    return (
                      <Menu.Item
                        key={_submenu.title}
                        onClick={() => handleChangePage(_submenu?.path ?? "/")}
                      >
                        {_submenu?.title}
                      </Menu.Item>
                    )
                  })}
                </Menu>
              )
              return (
                <Dropdown
                  overlay={menuCreate}
                  placement="bottom"
                  trigger={["hover", "click"]}
                  key={_button?.title}
                >
                  <Button
                    key={_button?.type}
                    type="link"
                    className={_button?.className}
                  >
                    {_button?.title}
                  </Button>
                </Dropdown>
              )
            }
            return (
              <Button
                onClick={() => handleChangePage(_button?.path ?? "")}
                key={_button?.type}
                type="link"
                className={_button?.className}
              >
                {_button?.title}
              </Button>
            )
          })}
        </div>
      </>
    )
  }, [])

  function handleChangePage(path: string) {
    history.push({ pathname: path })
  }

  return (
    <div className="header-left-wrapper">
      {renderGroupButton}
      {breakPoint?.lg && <SearchInput />}
    </div>
  )
}

export default HeaderLeft
