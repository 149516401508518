import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { changeMessage } from "../toastMessage/store/slice"

import "./index.scss"
import { socialNetwork } from "./constant"
import { Typography, Input } from "antd"
import { ArrowRightOutlined } from "@ant-design/icons"
import {
	SIDEBAR_WIDTH_DEFAULT,
	SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE,
} from "../layout/verticalLayout/sideBar"
import { Link } from "react-router-dom"
import { routes } from "@/router/constants"

const Footer = ({
	isActive,
	borderRadius,
	label,
	logo,
	onSelect,
	closeNavbar,
	isMobile,
	...props
}: any) => {
	const dispath = useDispatch()
	const [selectedIcon, setSelectedIcon] = useState<number>()

	const copyUrl = () => {
		let currentUrl = "Contact@openlivenft.com"
		navigator.clipboard.writeText(currentUrl)
		dispath(
			changeMessage({
				message: "Copy link success",
				statusCode: 200,
			})
		)
	}

	const footerStyled = useMemo(() => {
		if (!isMobile) {
			if (!closeNavbar) {
				return {
					left: `${SIDEBAR_WIDTH_DEFAULT}px`,
					width: `calc(100% - ${SIDEBAR_WIDTH_DEFAULT}px)`,
				}
			}
			return {
				left: `${SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE}px`,
				width: `calc(100% - ${SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE}px)`,
			}
		}
		return {
			left: "0",
			width: "100%",
		}
	}, [closeNavbar, isMobile])

	return (
		<footer>
			<div className="footer-container" style={footerStyled}>
				<div className="footer-left">
					<div className="footer-left-title">
						<Typography>Stay in the loop</Typography>
					</div>
					<div className="footer-left-content">
						<Typography className="content-description">
							Join our mailing list to stay in the loop with our newest feature
							releases, tips and tricks for navigating Monbase NFT
						</Typography>
						<Input
							className="input-address"
							placeholder="Your email address"
							suffix={<ArrowRightOutlined />}
						/>
					</div>
				</div>
				<div className="footer-right">
					<div className="footer-right-content">
						<Typography className="content-title">Marketplace</Typography>
						<div className="content-description">
							<Typography className="description-link">
								<Link to={routes.EXPLORE_NFT}>Explore</Link>
							</Typography>
							<Typography className="description-link">
								<a href="#">Help Center</a>
							</Typography>
						</div>
					</div>
					<div className="footer-right-content">
						<Typography className="content-title">Links</Typography>
						<div className="content-description">
							<Typography className="description-link">
								<a href="#">About Us</a>
							</Typography>
							<Typography className="description-link">
								<a
									href="https://nftdocument.monbase.com"
									target="_blank"
									rel="noreferrer"
								>
									Docs
								</a>
							</Typography>
							<Typography className="description-link">
								<a href="#">Support</a>
							</Typography>
						</div>
					</div>
					<div className="footer-right-content">
						<Typography className="content-title">Join Community</Typography>
						<div className="content-description">
							<Typography className="description-link">
								<a
									href="https://x.com/monbaseglobal"
									target="_blank"
									rel="noreferrer"
								>
									Twitter
								</a>
							</Typography>
							<Typography className="description-link">
								<a
									href="https://t.me/MonbaseGlobal_Announcements"
									target="_blank"
									rel="noreferrer"
								>
									Telegram Announcements
								</a>
							</Typography>
							<Typography className="description-link">
								<a
									href="https://t.me/monbaseglobalchat"
									target="_blank"
									rel="noreferrer"
								>
									Telegram Chat
								</a>
							</Typography>
							<Typography className="description-link">
								<a
									href="https://www.facebook.com/MonbaseCEX"
									target="_blank"
									rel="noreferrer"
								>
									Facebook
								</a>
							</Typography>
							{/* <Typography className="description-link">
								<a href="#" target="_blank" rel="noreferrer">
									Linkedin
								</a>
							</Typography> */}
							<Typography className="description-link">
								<a
									href="https://www.youtube.com/@monbase.global"
									target="_blank"
									rel="noreferrer"
								>
									Youtube
								</a>
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
