import { ReactNode, useMemo } from "react"
import NavLinks from "./components/NavLinks"
import "./sideBar.scss"
import { useHistory } from "react-router-dom"
import Footer from "components/common/footer"
import { routes } from "router/constants"
import ConnectWalletButton from "components/common/menu/headerMenu/components/buttons/connectWalletButton"
import { getAccessToken } from "@/utils/authentication"

interface ISideBarProps {
  children: ReactNode
  setCloseNavbar: any
  closeNavbar: boolean
}

export const SIDEBAR_WIDTH_DEFAULT = 0
export const SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE = 0

const SideBarComponent = ({
  children,
  setCloseNavbar,
  closeNavbar,
}: ISideBarProps) => {
  const history = useHistory()
  const classNameClose = useMemo(() => {
    return closeNavbar ? "close" : ""
  }, [closeNavbar])

  const isMobile = useMemo(() => {
    return window?.innerWidth < 768
  }, [window?.innerWidth])

  function handleBackToHome() {
    history.push({ pathname: routes.HOMEPAGE })
  }

  return (
    <>
      <nav className={`sidebar ${classNameClose}`}>
        <div>
          <img
            src={require("../../../../../assets/images/Logo-new.png")}
            alt=""
            height={48}
            width={44}
            onClick={handleBackToHome}
          />
        </div>

        <div className="menu_content">
          <NavLinks closeNavbar={closeNavbar} setCloseNavbar={setCloseNavbar} />
        </div>
        {!getAccessToken() && (
          <div className="sidebar_footer">
            <ConnectWalletButton />
          </div>
        )}
      </nav>
      <div className={isMobile ? "" : "body_content"}>{children}</div>

      <Footer closeNavbar={closeNavbar} isMobile={isMobile} />
    </>
  )
}

export const SideBar = SideBarComponent
