import CopyTooltip from "@/components/common/copyTooltip"
import {
	clearToken,
	selectWalletAddress,
} from "@/components/connectWallet/store/slice"
import {
	clearMyProfile,
	selectMyProfile,
} from "@/components/myProfile/store/slice"
import useGetOpvPrice from "@/hooks/useGetOpvPrice"
import useTokenBalance from "@/hooks/useTokenBalance"
import useTokenPrice from "@/hooks/useTokenPrice"
import { CloseOutlined } from "@ant-design/icons"
import { Card, message } from "antd"
import { useLayoutContext } from "components/common/menu/Menu"
import React, { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { shortenHexFor, showAddress } from "utils/util"
import ButtonCustom from "../buttons/buttonCustom"
import CartFooter from "./CartFooter"
import "./profilePopup.scss"
import { setModalAddFund } from "@/app/modal/actions"

interface ProfilePopupProps {
	children?: React.ReactNode
	items?: any[]
	visible: boolean
	setVisible: (visible: boolean) => void
}

const ProfilePopup = ({ visible, setVisible }: ProfilePopupProps) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { deactivate, setWalletAddress } = useLayoutContext()
	const usdtPrice = useTokenPrice("USDT")
	const [opvPrice] = useGetOpvPrice()
	const usdtBalance = useTokenBalance("USDT")
	const opvBalance = useTokenBalance("MBC")
	const myProfile = useSelector(selectMyProfile)
	const walletAddress = useSelector(selectWalletAddress)

	const handleLogOut = () => {
		deactivate?.()
		setVisible(true)
		dispatch(clearToken())
		dispatch(clearMyProfile())
		localStorage.setItem("accessToken", "")
		localStorage.setItem("refreshToken", "")
		localStorage.setItem("_accountId", "")
		setWalletAddress?.("")
		history.push("/connect-wallet")
		message.success("Logout Success", 3)
	}

	useEffect(() => {
		if (!visible) {
			document.body.style.overflow = "hidden"
		}
		if (visible) {
			document.body.style.overflow = "unset"
		}
	}, [visible])

	const handleCloseCart = useCallback(() => {
		setVisible(true)
	}, [setVisible])

	const renderCartFooter = useCallback(() => {
		return (
			<CartFooter>
				<div className="border rounded-[8px] p-[16px]">
					<div className="flex items-center justify-between pb-[16px] border-b">
						<div className="flex items-center">
							<div className="">
								<img
									src="/assets/images/wallets/metamask.webp"
									width="40"
									alt=""
								/>
							</div>
							<div className="ml-4">
								<p className="text-[12px] m-0">BSC</p>
								<CopyTooltip value={walletAddress || ""}>
									<div className="flex items-center space-x-4">
										<p className="text-[12px] m-0">
											{showAddress(walletAddress || "")}
										</p>
										<img
											src={require("@/assets/images/new-design/icon-copy-black.png")}
											width="20"
											alt=""
										/>
									</div>
								</CopyTooltip>
							</div>
						</div>
						<div>
							<ButtonCustom
								type="link"
								className="btn-clear-all"
								handleClick={handleLogOut}
							>
								<img
									src={require("@/assets/images/profile/logout.png")}
									width={32}
									alt=""
								/>
							</ButtonCustom>
						</div>
					</div>
					<div className="mt-8">
						<div className="space-y-4">
							{[
								{
									symbol: "MBC",
									icon: "mbc.png",
									balance: opvBalance,
									usdBalance: (+opvBalance * +opvPrice)?.toFixed(3),
								},
								{
									symbol: "USDT",
									icon: "usdt.png",
									balance: (+usdtBalance)?.toFixed(3),
									usdBalance: (+usdtBalance * usdtPrice.price)?.toFixed(3),
								},
							].map((item) => {
								return (
									<div
										className="flex justify-between items-center"
										key={item.symbol}
									>
										<div className="flex items-center space-x-4">
											<div>
												<img
													src={require(`@/assets/images/coins/${item.icon}`)}
													width="20"
													alt=""
												/>
											</div>
											<span className="font-bold text-[12px]">
												{item.balance} {item.symbol}
											</span>
										</div>
										<span className="text-[12px]">${item.usdBalance}</span>
									</div>
								)
							})}
						</div>
						<div className="mt-8 grid grid-cols-2 gap-8">
							<div>
								<ButtonCustom
									type="default"
									handleClick={() => {
										dispatch(
											setModalAddFund({
												toggle: true,
											})
										)
									}}
									block
								>
									Add fund
								</ButtonCustom>
							</div>
							<div>
								<ButtonCustom
									type="primary"
									handleClick={() =>
										window.open(
											"https://pancakeswap.finance/swap?outputCurrency=0x36C7B164F85D6F775cD128966D5819c7d36FEfF3&inputCurrency=0x55d398326f99059fF775485246999027B3197955"
										)
									}
									block
								>
									Swap
								</ButtonCustom>
							</div>
						</div>
					</div>
				</div>
			</CartFooter>
		)
	}, [opvBalance, usdtBalance, usdtPrice.price, walletAddress])

	const renderDropdownContent = useMemo(() => {
		return (
			<Card
				className={`card-wrapper ${visible ? "close" : "open"}`}
				title={
					<div className="flex items-center space-x-4 md:space-x-6">
						<img
							src={
								!myProfile?.avatar
									? require("@/assets/images/default-avatar.png")
									: myProfile.avatar
							}
							alt=""
							className="md:w-[64px] w-[40px] md:h-[64px] h-[40px] object-cover rounded-[4px]"
						/>
						<p className="m-0 font-bold text-base md:text-[24px] flex-1 text-ellipsis-custom max-w-[200px] sm:max-w-[230px]">
							{!myProfile?.displayName
								? shortenHexFor(walletAddress ?? "")
								: myProfile.displayName}
						</p>
					</div>
				}
				extra={[
					<CloseOutlined
						onClick={handleCloseCart}
						className="w-[24px] h-[24px] text-[24px]"
					/>,
				]}
			>
				<div className="cart-list">
					<div className="cart-container">
						<div className="space-y-4 md:space-y-6">
							{[
								{
									title: "Profile",
									icon: require(`@/assets/images/profile/user.png`),
									path: "/profile",
								},
								{
									title: "Collection",
									icon: require(`@/assets/images/profile/stack.png`),
									path: "/profile?view=collection",
								},
								{
									title: "Watchlist",
									icon: require(`@/assets/images/profile/eye.png`),
									path: "/watchlist",
								},
								...(myProfile?.user?.role?.toLowerCase() === "admin"
									? [
											{
												title: "Admin",
												icon: require(`@/assets/images/profile/UserCircleGear.png`),
												path: "/admin/overview",
											},
									  ]
									: []),
								{
									title: "Help Center",
									icon: require(`@/assets/images/profile/question.png`),
									path: "/help",
								},
								{
									title: "Setting",
									icon: require(`@/assets/images/profile/gear.png`),
									path: "/setting",
								},
							].map((item) => {
								return (
									<Link
										to={item.path}
										className="flex items-center space-x-2 md:space-x-4"
										key={item.title}
										onClick={handleCloseCart}
									>
										<img
											src={item.icon}
											alt=""
											className="md:w-[32px] w-[24px] md:h-[32px] h-[24px]"
										/>
										<span className=" md:text-base text-text font-bold text-xs">
											{item.title}
										</span>
									</Link>
								)
							})}
						</div>
					</div>
				</div>
				{renderCartFooter()}
			</Card>
		)
	}, [visible, handleCloseCart, renderCartFooter])

	return (
		<>
			<div
				className={`overlay  ${!visible ? "open" : "close"}`}
				onClick={handleCloseCart}
			/>
			<div
				className={`cart-profile-dropdown-wrapper  ${
					!visible ? "open" : "close"
				}`}
			>
				{renderDropdownContent}
			</div>
		</>
	)
}

export default ProfilePopup
