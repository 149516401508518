export const uploadImageConstants = {
  typeInput: {
    MULTIPLE: true,
    SINGLE: false,
  },
  typeUpload: {
    COVER: "cover",
    AVATAR: "avatar",
    IMAGE: "item",
    COLLECTION: "collection",
  },
}

export const chain = [
  {
    id: "BNB_CHAIN",
    name: "BNB Smart Chain",
    link: process.env.REACT_APP_EXPLORER,
    hash: `${process.env.REACT_APP_EXPLORER}tx/`,
    address: `${process.env.REACT_APP_EXPLORER}address/`,
    token: `${process.env.REACT_APP_EXPLORER}token/`,
  },
]

export const RATIO_IMAG_SIZE = 0.88

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "omarket-d0410.firebaseapp.com",
  projectId: "omarket-d0410",
  storageBucket: "omarket-d0410.appspot.com",
  messagingSenderId: "503278152294",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-B9HWWTBP9B",
}
