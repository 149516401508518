import navigation from "../../navigation"
import NavLinkItem from "../NavLinkItem"
import "./navLink.scss"

interface NavLinkProps {
  closeNavbar: boolean
  setCloseNavbar: any
}

const NavLinks = ({ closeNavbar, setCloseNavbar }: NavLinkProps) => {
  return (
    <ul className="menu_items space-y-4">
      {navigation()?.map((item) => {
        return (
          <NavLinkItem
            key={item?.title}
            item={item}
            setCloseNavbar={setCloseNavbar}
          ></NavLinkItem>
        )
      })}
    </ul>
  )
}

export default NavLinks
