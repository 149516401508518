import sample from "lodash/sample"

if (
  process.env.REACT_APP_SUPPORT_CHAIN !== `56` &&
  (!process.env.REACT_APP_RPC_URL ||
    !process.env.REACT_APP_RPC_URL_1 ||
    !process.env.REACT_APP_RPC_URL_2)
) {
  throw Error("One base RPC URL is undefined")
}

// Array of available nodes to connect to
export const nodes = [
  process.env.REACT_APP_RPC_URL,
  process.env.REACT_APP_RPC_URL_1,
  process.env.REACT_APP_RPC_URL_2,
]

const getNodeUrl = () => {
  // Use custom node if available (both for development and production)
  // However on the testnet it wouldn't work, so if on testnet - comment out the NEXT_PUBLIC_NODE_PRODUCTION from env file
  if (process.env.REACT_APP_SUPPORT_CHAIN === `56`) {
    return process.env.REACT_APP_RPC_URL
  }
  return sample(nodes)
}

export default getNodeUrl
