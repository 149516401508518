export const routes = {
  LOGIN: "/connect-wallet",
  HOMEPAGE: "/",
  CATEGORY: "/category/:categoryName",
  EVENT: "/event",
  NFT_DETAIL_PARAMS: "/market/:id",
  NFT_INVENTORY: "/inventory",
  NFT_INVENTORY_PARAMS: "/inventory/:contractAddress/:tokenId",
  NFT_IN_EVENT: "/item-event/:contractAddress/:tokenId",
  MY_PROFILE: "/profile",
  EDIT_PROFILE: "/edit-profile",
  CREATE_NFT: "/create-nft",
  EXPLORE_NFT: "/explore",
  CHANGE_CHAIN: "/change-chain",
  CONNECT_WALLET: "/connect-wallet",
  CREATE_COLLECTION: "/collection/create",
  EDIT_COLLECTION: "/collection/:id/edit",

  COLLECTION_HOLD: "/collections/:id/hold",
  LAUNCHPAD: "/launchpad",
  DETAIL_COLLECTION_NFT: "/collections/:id/detail-nft",
  AIRDROP: "/airdrop",
  DETAIL_COLLECTION: "/collections/:id/detail",
  UPDATE_COLLECTION: "/collections/:id/update",
  SWAP: "/swap",
  SETTINGS_LAYOUT: "/setting",
  REFERRAL_LAYOUT: "/referral",
  LASTEST_TRANSACTIONS: "/lastest-transactions",
  ALL_EVENT: "/events",
  NOTIFICATIONS: "/notification",
  SEARCH_RESULT: "/search-result",
  PROFILE_VIEW: "/profile-view/:address",
  HELP_CENTER: "/help-center",
  HELP_BUYING: "/help-center/buying",
  HELP_SELLING: "/help-center/selling",
  HELP_CREATING: "/help-center/creating",
  HELP_GETTING: "/help-center/getting-started",
  HELP_SEARCH_RESULT: "/help-center/search-result",
  WATCH_LIST: "/watch-list",
  RANKING: "/ranking",
  HELP_REQUEST: "/help-center/submit-request",
  SEARCH_HELP: "/search-result-help",
  ACTIVITIES: "/activities",
  BRAND_CATEGORY: "/brand-category",
  REWARDS: "/reward",
  REWARDS_COLLECTION: "/reward-collection",
  REWARDS_HISTORY: "/reward/history",
  REWARDS_LEADER_BOARD: "/reward/leader-board",
  REWARDS_VIEW_RULES: "/reward/view-rules",
  CONFIRM_USER: "/verify-email/",
  BRAND_DETAILS: "/brand-details",
  AUCTION_LAYOUT: "/auction",
  LOYALTY: "/loyalty",
  LIST_ON_SALE: "/list-on-sale/:id",
  EDIT_NFT: "/nft/:id/edit",
  DETAIL_NFT: "/nft/detail/:id",
  VESTING: "/vesting",
  VESTING_HISTORY: "/vesting/history",
  ADMIN_OVERVIEW: "/admin/overview",
}
