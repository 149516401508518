import { Card, Checkbox } from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import "./cartDropdown.scss"
import { CloseOutlined } from "@ant-design/icons"
import CartItemDropdown from "./CartItemDropdown"
import CartTitle from "./CartTitle"
import ButtonCustom from "../buttons/buttonCustom"
import CartFooter from "./CartFooter"
import InformationPaymentStep from "./InformationPaymentStep"
import { useDispatch, useSelector } from "react-redux"
import { useClearCart } from "@/hooks/useCart"
import { post } from "@/network/axiosClient"
import useGetOpvPrice from "@/hooks/useGetOpvPrice"
import { formatAmount } from "@/utils/Number"
import { setModalAddFund } from "@/app/modal/actions"
import useGetBalanceOpv from "@/hooks/useGetBalanceOpv"
import { useToasts } from "react-toast-notifications"

interface CartDropdownProps {
  children?: React.ReactNode
  items?: any[]
  visible: boolean
  setVisible: (visible: boolean) => void
}
export enum STEP_CART {
  CHECK_OUT = 1,
  PROCESS_PAY = 2,
}

const CartDropdownComponent = ({
  children,
  items = [],
  visible,
  setVisible,
}: CartDropdownProps) => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { cart } = useSelector((state: any) => state.cart)
  const clearCart = useClearCart()
  const [opvPrice] = useGetOpvPrice()
  const [opvBalance] = useGetBalanceOpv()

  const [stepCart, setStepCart] = useState<STEP_CART>(STEP_CART.CHECK_OUT)
  const [NFTsList, setNFTsList] = useState<any>([])
  const [listCart, setListCart] = useState<any>({
    list: [],
    totalOPV: 0,
    totalPrice: 0,
  })
  const [selectItemNftForBuy, setSelectItemNftForBuy] = useState<any>({
    list: [],
    totalOPV: 0,
    totalPrice: 0,
  })

  const openModalAddFund = useCallback(() => {
    dispatch(
      setModalAddFund({
        toggle: true,
      })
    )
  }, [dispatch])

  const handleBuyNow = useCallback(() => {
    addToast("The feature not available at the moment", {
      appearance: "info",
    })
  }, [addToast])

  useMemo(async () => {
    if (cart?.length > 0) {
      const parameNft = {
        skip: 0,
        limit: 10,
        sort: {
          field: "created_at",
          order: -1,
        },
        filter: [
          {
            name: "_id",
            arr_value: cart,
            operator: "in",
          },
        ],
      }

      const resultsGetNFT = await post("nfts/search", parameNft)

      if (
        resultsGetNFT?.data?.data?.docs !== null &&
        resultsGetNFT?.data?.data?.docs !== undefined
      ) {
        setNFTsList(resultsGetNFT?.data?.data?.docs)
      } else {
        setNFTsList([])
      }
    }
  }, [cart])

  useEffect(() => {
    setStepCart(STEP_CART.CHECK_OUT)
    if (!visible) {
      document.body.style.overflow = "hidden"
    }
    if (visible) {
      document.body.style.overflow = "unset"
    }
  }, [visible])

  useEffect(() => {
    const totalOPV = NFTsList?.reduce(
      (total: number, obj: any) => total + obj.currentPrice,
      0
    )

    const totalPrice = totalOPV * +opvPrice

    setListCart({
      list: NFTsList,
      totalOPV: +formatAmount(totalOPV),
      totalPrice: +formatAmount(totalPrice),
    })
  }, [NFTsList, opvPrice])

  const handleCloseCart = useCallback(() => {
    setVisible(true)
  }, [setVisible])

  const handleStep = useCallback(
    (step: STEP_CART) => {
      if (stepCart === STEP_CART.CHECK_OUT) {
        setStepCart(step + 1)
      } else {
        if (
          opvBalance !== undefined &&
          opvBalance <
            NFTsList?.reduce(
              (total: number, obj: any) => total + obj.currentPrice,
              0
            )
        ) {
          openModalAddFund()
        } else {
          setStepCart(step - 1)
        }
      }
    },
    [NFTsList, opvBalance, openModalAddFund, stepCart]
  )

  const handleClearCart = useCallback(() => {
    setListCart({ list: [], totalOPV: 0, totalPrice: 0 })
    clearCart()
  }, [clearCart])

  const handleSelectItem = useCallback(
    (jsonArray) => {
      const value = jsonArray.map((jsonString: any) => JSON.parse(jsonString))

      const totalOPV = value?.reduce(
        (total: number, obj: any) => total + obj.currentPrice,
        0
      )

      const totalPrice = totalOPV * +opvPrice

      setSelectItemNftForBuy({
        list: value,
        totalOPV: +formatAmount(totalOPV),
        totalPrice: +formatAmount(totalPrice),
      })
    },
    [opvPrice]
  )

  const renderCartTitle = useMemo(() => {
    const children = (
      <ButtonCustom type="link" handleClick={handleClearCart}>
        Clear All
      </ButtonCustom>
    )
    return (
      <CartTitle countItems={listCart?.list?.length ?? 0}>{children}</CartTitle>
    )
  }, [handleClearCart, listCart?.list?.length])

  const renderCartFooter = useCallback(
    (title: string, step: STEP_CART, totalPrice, totalOPV) => {
      let btn = (
        <ButtonCustom
          disabled={selectItemNftForBuy?.list.length === 0}
          type="primary"
          handleClick={() => handleStep(step)}
        >
          {title}
        </ButtonCustom>
      )

      if (step === STEP_CART.CHECK_OUT) {
        btn = (
          <ButtonCustom
            disabled={selectItemNftForBuy?.list.length === 0}
            type="primary"
            handleClick={() => handleStep(step)}
          >
            {title}
          </ButtonCustom>
        )
      }
      if (step === STEP_CART.PROCESS_PAY) {
        if (
          opvBalance !== undefined &&
          opvBalance <
            selectItemNftForBuy?.list?.reduce(
              (total: number, obj: any) => total + obj.currentPrice,
              0
            )
        ) {
          btn = (
            <ButtonCustom
              disabled={selectItemNftForBuy?.list.length === 0}
              type="primary"
              handleClick={() => handleStep(step)}
            >
              Add fund
            </ButtonCustom>
          )
        } else {
          btn = (
            <ButtonCustom
              disabled={selectItemNftForBuy?.list.length === 0}
              type="primary"
              handleClick={() => handleBuyNow()}
            >
              Process to pay
            </ButtonCustom>
          )
        }
      }

      return (
        <CartFooter buttonContent={btn}>
          <InformationPaymentStep
            step={step}
            totalOPV={totalOPV}
            totalPrice={totalPrice}
          />
        </CartFooter>
      )
    },
    [selectItemNftForBuy, handleStep, opvBalance, handleBuyNow]
  )

  const renderDropdownContent = useMemo(() => {
    if (stepCart === STEP_CART.CHECK_OUT) {
      return (
        <Card
          className={`card-wrapper ${visible ? "close" : "open"}`}
          extra={<CloseOutlined onClick={handleCloseCart} />}
          title={<p className="font-bold text-[24px]">Your bag</p>}
        >
          <div className="cart-list">
            {renderCartTitle}
            <div className="cart-container">
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={handleSelectItem}
                defaultValue={selectItemNftForBuy?.list?.map((item: any) =>
                  JSON.stringify(item)
                )}
              >
                <div className="list-item">
                  {listCart?.list?.map((_item: any) => (
                    <div
                      className="wrap-item-cart-checkbox"
                      key={_item.id}
                      onClick={() => {
                        if (
                          selectItemNftForBuy?.list?.[0]?.contractAddress &&
                          selectItemNftForBuy.list[0].contractAddress !==
                            _item?.contractAddress
                        ) {
                          addToast(
                            "Only select nft from the same collection!",
                            {
                              appearance: "info",
                            }
                          )
                        }
                      }}
                    >
                      <Checkbox
                        value={JSON.stringify(_item)}
                        disabled={
                          selectItemNftForBuy?.list?.[0]?.contractAddress &&
                          selectItemNftForBuy.list[0].contractAddress !==
                            _item?.contractAddress
                        }
                      >
                        <CartItemDropdown
                          countItem={_item?.countItem}
                          id={_item?.id}
                          description={_item?.description}
                          title={_item?.name}
                          opv={_item?.currentPrice}
                          price={_item?.currentPrice}
                          url={_item?.mediaUrl}
                          key={_item?.id}
                        />
                      </Checkbox>
                    </div>
                  ))}
                </div>
              </Checkbox.Group>
            </div>
          </div>
          {renderCartFooter(
            "CheckOut",
            stepCart,
            selectItemNftForBuy?.totalOPV,
            selectItemNftForBuy?.totalPrice
          )}
        </Card>
      )
    }
    return (
      <Card
        className={`card-wrapper ${visible ? "close" : "open"}`}
        title="Checkout"
        extra={[<CloseOutlined onClick={handleCloseCart} />]}
      >
        <div className="cart-list">
          <div className="cart-container">
            <div className="list-item">
              {listCart?.list?.map((_item: any) => {
                return (
                  <CartItemDropdown
                    countItem={_item?.countItem}
                    id={_item?.id}
                    description={_item?.description}
                    title={_item?.name}
                    opv={_item?.currentPrice}
                    price={_item?.currentPrice}
                    url={_item?.mediaUrl}
                    key={_item?.id}
                  />
                )
              })}
            </div>
          </div>
        </div>
        {renderCartFooter(
          "Process to pay",
          stepCart,
          selectItemNftForBuy?.totalOPV,
          selectItemNftForBuy?.totalPrice
        )}
      </Card>
    )
  }, [
    stepCart,
    visible,
    handleCloseCart,
    listCart?.list,
    renderCartFooter,
    renderCartTitle,
    handleSelectItem,
    selectItemNftForBuy,
  ])

  return (
    <>
      <div
        className={`overlay  ${!visible ? "open" : "close"}`}
        onClick={handleCloseCart}
      />
      <div className={`cart-dropdown-wrapper`}>{renderDropdownContent}</div>
    </>
  )
}

export const CartDropdown = CartDropdownComponent
