// import React from "react"
import ReactDOM from "react-dom"
import { Web3ReactProvider } from "@web3-react/core"
// import { WagmiProvider } from "contexts/WagmiContext"
// import { client } from "utils/wagmi"
import { ToastProvider } from "react-toast-notifications"
import "./tailwind.css"
import "antd/dist/antd.less"
import "antd/dist/antd.css"

import App from "./App"
import reportWebVitals from "./reportWebVitals"
import getLibrary, { connectors } from "./utils/getLibrary"
import "./index.css"
import "../src/assets/css/global.scss"
import "@pathofdev/react-tag-input/build/index.css"
import React from "react"
import * as Sentry from "@sentry/react"

Sentry.init({
  dsn: "https://82ceacd9c4ddd9e167994579ccf3425e@o470220.ingest.sentry.io/4505798292930560",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
  // <WagmiProvider client={client}>
  <ToastProvider autoDismiss={true} autoDismissTimeout={3000}>
    {/* <Web3ReactProvider getLibrary={getLibrary}> */}
    <Web3ReactProvider connectors={connectors}>
      <App />
    </Web3ReactProvider>
  </ToastProvider>,
  // </WagmiProvider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
