import { Button } from "antd"
import "./connectWallet.scss"

import { useState } from "react"
import ConnectWalletModal from "../modals/ConnectWallet"
import { useLayoutContext } from "../../../Menu"

interface ConnectWalletButtonProps {}

const ConnectWalletButton = ({}: ConnectWalletButtonProps) => {
  const [openConnectWallet, setOpenConnectWallet] = useState(false)

  function openConnectWalletModal() {
    setOpenConnectWallet(true)
  }

  return (
    <>
      <ConnectWalletModal
        open={openConnectWallet}
        setOpen={setOpenConnectWallet}
      />

      <Button className="btn-connect-wallet" onClick={openConnectWalletModal}>
        Connect wallet
      </Button>
    </>
  )
}

export default ConnectWalletButton
