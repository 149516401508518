/* eslint-disable react-hooks/rules-of-hooks */
import {
	setModalAddFund,
	setModalBuyNow,
	setModalSuccess,
} from "@/app/modal/actions"
import useCheckNotification from "@/hooks/useCheckNotification"
import useGetBalanceOpv from "@/hooks/useGetBalanceOpv"
import useGetOpvPrice from "@/hooks/useGetOpvPrice"
import useGetPreListing from "@/hooks/useGetPreListing"
import { Modal } from "antd"
import { minBy } from "lodash"
import { memo, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import Web3 from "web3"
import {
	useCancelOrder,
	useFillOrder,
	useGenSignature,
} from "../../hooks/useBuyTransaction"
import useERC20Contract from "../../hooks/useERC20Contract"
import useGetGasPrice from "../../hooks/useGetGasPrice"
import useMarketplaceContract from "../../hooks/useMarketplaceContract"
import { StatusCodeResponse } from "../../network/constants"
import NFTDetailServices from "../../network/nftDetail/services"
import { MARKETPLACE_ADDRESS, OPV } from "../../utils/constant"
import {
	formatterUnit,
	getTransactionReceiptMined,
	toLocaleString,
} from "../../utils/util"
import { hideLoading, showLoading } from "../common/loading/store/slice"
import { clearToken, selectWalletAddress } from "../connectWallet/store/slice"
import ArrowDropdownIcon from "../icons/ArrowDropdownIcon"
import { clearMyProfile } from "../myProfile/store/slice"
import "./styles.scss"

export enum EnumERCStandard {
	ERC_721 = "ERC_721",
	ERC_1155 = "ERC_1155",
}

const web3 = new Web3(
	Web3.givenProvider ||
		"wss://speedy-nodes-nyc.moralis.io/d682253c26212ac170504417/bsc/testnet/ws"
)

const ModalBuyNow = () => {
	// state & hook
	const history = useHistory()
	const dispatch = useDispatch()
	const account = useSelector(selectWalletAddress)
	const { addToast } = useToasts()
	const { modalBuyNow } = useSelector((state: any) => state.modal)
	const [opvPrice] = useGetOpvPrice()
	const [opvBalance] = useGetBalanceOpv()
	const { getUnreadNotification } = useCheckNotification()
	const getPreListing = useGetPreListing()

	const [openPriceDetailt, setOpenPriceDetailt] = useState<boolean>(true)
	const [transaction, setTransaction] = useState<any>(null)

	const [estimateGas, setEstimateGas] = useState<number>(0)
	const [price, setPrice] = useState<string>("0")
	const [uri, setUri] = useState<string>("")
	const [isApproved, setIsApproved] = useState<boolean>(false)
	const [serviceFee, setServiceFee] = useState<any>({
		serviceFee: 0,
		creatorEarning: 0,
	})

	// smart contract
	const marketplaceContract = useMarketplaceContract(MARKETPLACE_ADDRESS)
	const opvContract = useERC20Contract(OPV)

	useMemo(() => {
		if (modalBuyNow?.transaction) {
			setTransaction(modalBuyNow?.transaction)
		} else if (modalBuyNow?.data?.currentListing?.length > 0) {
			const selectedTrans = minBy(
				modalBuyNow?.data?.currentListing.filter(
					(x: any) => x.quantity >= modalBuyNow?.quantity
				),
				"quantity"
			)

			setTransaction(selectedTrans)
		}
	}, [
		modalBuyNow?.transaction,
		modalBuyNow?.data?.currentListing,
		modalBuyNow?.quantity,
	])

	useMemo(async () => {
		const decimal = await opvContract?.decimals()

		if (account && modalBuyNow?.data?.currentPrice) {
			const bgPrice = modalBuyNow?.data?.currentPrice * 10 ** decimal
			setPrice(toLocaleString(bgPrice))
		}
	}, [account, modalBuyNow?.data?.currentPrice, opvContract])

	useMemo(async () => {
		if (modalBuyNow?.data?.id && !modalBuyNow?.data?.freeze) {
			const uriRes = await NFTDetailServices.getNFTFreezeUri(
				modalBuyNow?.data?.id
			)
			if (uriRes?.status === StatusCodeResponse.SUCCESS) {
				const stringArray = uriRes?.data?.data?.full_path?.split("/")
				setUri(stringArray[stringArray.length - 1])
			} else {
				addToast(uriRes?.data?.message ?? "Buy nft is failed!.", {
					appearance: "error",
				})

				dispatch(
					setModalBuyNow({
						toggle: false,
						data: {},
					})
				)
			}
		}
	}, [addToast, dispatch, modalBuyNow?.data?.freeze, modalBuyNow?.data?.id])

	const handleGenTransSignature = useGenSignature()
	const handleCancelOrder = useCancelOrder()
	const handFillOrder = useFillOrder()
	const getGasPrice = useGetGasPrice()

	const buyNft = async () => {
		let fillOrderTrans: any = null

		handleGenTransSignature(
			transaction?.id,
			{ amount: +modalBuyNow?.quantity, onlyGenSig: false },
			async (res: any) => {
				if (opvContract && marketplaceContract) {
					if (res) {
						const { sig } = res
						fillOrderTrans = res.transaction

						if (!fillOrderTrans) {
							addToast("Cannot create fill order, please try it again.", {
								appearance: "error",
							})
							return
						}
						// rang of uint256
						let approve_amount =
							"115792089237316195423570985008687907853269984665640564039457584007913129639935" //(2^256 - 1 )

						const params = modalBuyNow?.data?.freeze
							? [
									[modalBuyNow?.data?.ownerAddress],
									[price.toString()],
									[modalBuyNow?.quantity],
									[fillOrderTrans?.id],
									[sig],
									[modalBuyNow?.data?.nftId],
									OPV,
									modalBuyNow?.data?.catalog?.contractAddress,
							  ]
							: [
									[modalBuyNow?.data?.ownerAddress],
									[price.toString()],
									[modalBuyNow?.quantity],
									[fillOrderTrans?.id],
									[sig],
									[modalBuyNow?.data?.nftId],
									OPV,
									modalBuyNow?.data?.catalog?.contractAddress,
									uri ? [uri] : [],
							  ]

						if (!isApproved) {
							try {
								opvContract
									.approve(MARKETPLACE_ADDRESS, approve_amount)
									.then(async (res: any) => {
										const receipt: any = await getTransactionReceiptMined(
											web3,
											res?.hash,
											500
										)

										if (receipt?.status) {
											setIsApproved(true)
											dispatch(hideLoading())
										}
									})
									.catch((e: any) => {
										dispatch(
											setModalBuyNow({
												toggle: false,
												data: {},
											})
										)
										dispatch(hideLoading())
										addToast("Buy nft is failed!", {
											appearance: "error",
										})
									})
							} catch (error) {
								dispatch(
									setModalBuyNow({
										toggle: false,
										data: {},
									})
								)
								dispatch(hideLoading())
								addToast("Buy nft is failed!", {
									appearance: "error",
								})
							}
						} else {
							try {
								marketplaceContract[
									modalBuyNow?.data?.freeze
										? "fillOrderWithSignOnChain"
										: "fillOrderWithSignature"
								](...params)
									.then(async (res: any) => {
										// save transaction hash
										await handFillOrder(fillOrderTrans?.id, {
											transactionHash: res?.hash,
										})

										const receipt: any = await getTransactionReceiptMined(
											web3,
											res?.hash,
											500
										)

										if (receipt) {
											dispatch(hideLoading())
											addToast("Buy nft is successfully!.", {
												appearance: "success",
											})
											getUnreadNotification()
											dispatch(
												setModalSuccess({
													toggle: true,
													data: {
														title: "Your purchase is success",
														nft: modalBuyNow?.data,
														transaction: res?.hash,
													},
												})
											)
											dispatch(
												setModalBuyNow({
													toggle: false,
													data: {},
												})
											)
										}
									})
									.catch(async (e: any) => {
										handleCancelOrder(fillOrderTrans?.id, () => {
											addToast("Buy nft is failed!", {
												appearance: "error",
											})
										})
										dispatch(
											setModalBuyNow({
												toggle: false,
												data: {},
											})
										)
										dispatch(hideLoading())
									})
							} catch (error) {
								handleCancelOrder(fillOrderTrans?.id, () => {
									addToast("Buy nft is failed!", {
										appearance: "error",
									})
								})
								dispatch(
									setModalBuyNow({
										toggle: false,
										data: {},
									})
								)
								dispatch(hideLoading())
							}
						}
					}
				} else {
					dispatch(hideLoading())
					dispatch(clearToken())
					dispatch(clearMyProfile())
					localStorage.setItem("accessToken", "")
					localStorage.setItem("refreshToken", "")
					localStorage.setItem("_accountId", "")
					history.push("/connect-wallet")
				}
			},
			(err: any) => {
				dispatch(hideLoading())
				addToast("Cannot generate buy nft signature.", {
					appearance: "error",
				})
			}
		)
	}

	const handlePayment = async () => {
		dispatch(showLoading())

		try {
			await buyNft()
		} catch (e) {
			dispatch(hideLoading())
			addToast("Buy nft is failed!.", {
				appearance: "error",
			})
		}
	}

	const handleCancel = () => {
		setIsApproved(false)
		setPrice("0")
		setEstimateGas(0)
		setUri("")
		dispatch(
			setModalBuyNow({
				toggle: false,
				data: {},
			})
		)
	}

	const handleAddFund = () => {
		dispatch(
			setModalBuyNow({
				toggle: false,
				data: {},
			})
		)
		dispatch(
			setModalAddFund({
				toggle: true,
			})
		)
	}

	useEffect(() => {
		if (opvContract && price) {
			opvContract
				.allowance(account, MARKETPLACE_ADDRESS)
				.then(async (balanceAllowance: any) => {
					if (+balanceAllowance >= +price) {
						setIsApproved(true)
					} else {
						setIsApproved(false)
					}
				})
		}
	}, [account, opvContract, price])

	useEffect(() => {
		if (
			transaction?.id &&
			uri &&
			price &&
			marketplaceContract &&
			isApproved &&
			modalBuyNow?.quantity
		) {
			handleGenTransSignature(
				transaction?.id,
				{ amount: +modalBuyNow?.quantity, onlyGenSig: true },
				async (res: any) => {
					const { sig } = res

					const params = modalBuyNow?.data?.freeze
						? [
								[modalBuyNow?.data?.ownerAddress],
								[price.toString()],
								[modalBuyNow?.quantity],
								[transaction?.id],
								[sig],
								[modalBuyNow?.data?.nftId],
								OPV,
								modalBuyNow?.data?.catalog?.contractAddress,
						  ]
						: [
								[modalBuyNow?.data?.ownerAddress],
								[price.toString()],
								[modalBuyNow?.quantity],
								[transaction?.id],
								[sig],
								[modalBuyNow?.data?.nftId],
								OPV,
								modalBuyNow?.data?.catalog?.contractAddress,
								uri ? [uri] : [],
						  ]

					const gasFee = await marketplaceContract?.estimateGas[
						modalBuyNow?.data?.freeze
							? "fillOrderWithSignOnChain"
							: "fillOrderWithSignature"
					](...params)

					const gasPrice = await getGasPrice()
					setEstimateGas(gasPrice * (gasFee ? gasFee?.toNumber() / 10 ** 9 : 0))
				}
			)
		}
	}, [
		price,
		transaction?.id,
		uri,
		isApproved,
		marketplaceContract,
		modalBuyNow?.quantity,
		modalBuyNow?.data?.ownerAddress,
		modalBuyNow?.data?.nftId,
		modalBuyNow?.data?.catalog?.contractAddress,
		modalBuyNow?.data?.freeze,
		handleGenTransSignature,
		getGasPrice,
	])

	useMemo(async () => {
		if (modalBuyNow?.data?.id) {
			const paramGetPreListing = {
				nftId: modalBuyNow?.data?.id,
				listingType: modalBuyNow?.data?.listing,
				listingPrice: +modalBuyNow?.data?.currentPrice * modalBuyNow?.quantity,
				quantity: +modalBuyNow?.quantity,
				duration: 86400,
			}

			const rsFee = await getPreListing(paramGetPreListing)
			setServiceFee(rsFee)
		}
	}, [
		getPreListing,
		modalBuyNow?.data?.currentPrice,
		modalBuyNow?.data?.id,
		modalBuyNow?.quantity,
		modalBuyNow?.data?.listing,
	])

	return (
		<Modal
			title="Checkout"
			visible={modalBuyNow?.toggle}
			onOk={handlePayment}
			onCancel={handleCancel}
			className="modal-buy-now-nft"
			footer={false}
			style={{ bottom: 0 }}
		>
			<div className="approve-nft-info">
				<div className="approve-nft-info-image">
					{modalBuyNow?.data?.animationUrl &&
					modalBuyNow?.data?.animationUrl !== "Unknown" ? (
						<iframe
							src={`${
								modalBuyNow?.data?.animationUrl || modalBuyNow?.data?.mediaUrl
							}?autoplay=1&loop=1&autopause=0&controls=0&muted=1`}
							className="absolute top-0 left-0 w-full h-full object-cover duration-[2000s] pointer-events-none"
							allowFullScreen
							title={modalBuyNow?.data?.name}
						/>
					) : (
						<img
							src={
								modalBuyNow?.data?.mediaUrl !== "Unknown"
									? modalBuyNow?.data?.mediaUrl
									: require("@/assets/images/default-avatar.png")
							}
							alt={modalBuyNow?.data?.name}
						/>
					)}

					<div className="approve-nft-info-image-quantity">
						<p>
							<span>{modalBuyNow?.quantity}</span>
						</p>
					</div>
				</div>
				<div className="approve-nft-info-name">
					<p>{modalBuyNow?.data?.name}</p>
					<p>
						{modalBuyNow?.data?.catalog?.displayName
							? modalBuyNow?.data?.catalog?.displayName
							: modalBuyNow?.data?.catalog?.name}
					</p>
				</div>
				<div className="approve-nft-info-price">
					<p>{modalBuyNow?.data?.currentPrice} MBC</p>
					<p>
						$
						{modalBuyNow?.data?.currentPrice
							? formatterUnit(modalBuyNow?.data?.currentPrice * +opvPrice, 3)
							: 0}
					</p>
				</div>
			</div>
			<div className="approve-nft-price">
				<button
					type="button"
					onClick={() => setOpenPriceDetailt((prev) => !prev)}
				>
					<p>
						Total{" "}
						<b>{modalBuyNow?.data?.currentPrice * modalBuyNow?.quantity} MBC</b>
						+ <b>{estimateGas ?? 0} BNB</b>
					</p>

					<ArrowDropdownIcon
						style={{
							transform: openPriceDetailt ? "rotate(0)" : "rotate(180deg)",
							transition: "0.2s",
						}}
					/>
				</button>

				{openPriceDetailt && (
					<>
						<div className="approve-nft-price-wrap-item">
							<div className="approve-nft-price-item">
								<div>Listing price</div>
								<div>
									{modalBuyNow?.data?.currentPrice * modalBuyNow?.quantity} MBC
									($
									{modalBuyNow?.data?.currentPrice
										? formatterUnit(
												modalBuyNow?.data?.currentPrice *
													+opvPrice *
													modalBuyNow?.quantity,
												3
										  )
										: 0}
									)
								</div>
							</div>
							<div className="approve-nft-price-item">
								<div>Service fee</div>
								<div>
									<b>
										{formatterUnit(serviceFee?.serviceFee, 3)} MBC ($
										{serviceFee?.serviceFee
											? formatterUnit(serviceFee?.serviceFee * +opvPrice, 3)
											: 0}
										)
									</b>
								</div>
							</div>
						</div>
					</>
				)}
			</div>

			<div className="approve-nft-action">
				{+opvBalance >= +modalBuyNow?.data?.currentPrice ? (
					<button type="button" onClick={handlePayment}>
						{isApproved ? "Proceed to payment" : "Approve"}
					</button>
				) : (
					<>
						<button type="button" onClick={handleAddFund}>
							Add fund
						</button>
						<p className="m-0 text-[#999] md:text-base text-xs text-center lg:mt-[24px] mt-[16px]">
							Insufficient MBC Balance
						</p>
					</>
				)}
			</div>
		</Modal>
	)
}

export default memo(ModalBuyNow)
