import "./navLink.scss"
import { NavLink } from "../../navigation"
import ArrowUpIcon from "@/components/icons/ArrowUpIcon"
import { useState } from "react"
import { useLocation, Link } from "react-router-dom"

interface NavLinkProps {
  item: NavLink
  setCloseNavbar: any
}

const NavLinkItem = ({ item, setCloseNavbar }: NavLinkProps) => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const { icon: Icon } = item

  const renderContent = (item: NavLink) => {
    const hasSubmenu = !!item.subMenu
    const isActive =
      location.pathname === item.path ||
      item.subMenu?.some((subMenu) => subMenu.path === location.pathname)
    return (
      <div
        className={["nav_link", isActive ? "active" : ""].join(" ")}
        onClick={
          hasSubmenu
            ? () => setOpen((prev) => !prev)
            : () => setCloseNavbar((prev: boolean) => !prev)
        }
      >
        <div className="flex items-center">
          <span className="navlink_icon">{Icon && <Icon></Icon>}</span>
          <span className="navlink ml-4">{item?.title}</span>
          {item?.subMenu && (
            <i className="bx bx-chevron-right arrow-left">
              <ArrowUpIcon
                className={["transform ", open ? "" : "rotate-180"].join(" ")}
              />
            </i>
          )}
        </div>
        {open && !!item?.subMenu && (
          <div className="space-y-4 mt-4 sub-menu">
            {item.subMenu.map((subMenu) => {
              const active = location.pathname === subMenu.path
              return (
                <Link
                  to={subMenu.path ? subMenu.path : ""}
                  className={[
                    "block pl-[8px] py-[10px] hover:bg-[#F0F0F0] rounded-[8px]",
                    active
                      ? "text-[#1DA6DD]"
                      : "text-[#666666] hover:text-[#666666]",
                  ].join(" ")}
                  onClick={() => setCloseNavbar((prev: boolean) => !prev)}
                  key={subMenu.path}
                >
                  {subMenu.title}
                </Link>
              )
            })}
          </div>
        )}
      </div>
    )
  }
  return (
    <li className="item hover:bg-[]" key={item?.title}>
      {item.redirect ? (
        <a href={item.redirect}>{renderContent(item)}</a>
      ) : item.path ? (
        <Link to={item.path}>{renderContent(item)}</Link>
      ) : (
        <>{renderContent(item)}</>
      )}
    </li>
  )
}

export default NavLinkItem
