import "./styles.scss"
import { Button, InputNumber, Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { setModalBidNow } from "@/app/modal/actions"
import useERC20Contract from "@/hooks/useERC20Contract"
import { AUCTION_CONTRACT, OPV } from "@/utils/constant"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useWeb3React } from "@web3-react/core"
import useAuctionContract from "@/hooks/useAuctionContract"
import { formatAmount, roundWithCeil } from "@/utils/Number"
import Web3 from "web3"
import {
  getTransactionReceiptMined,
  parseIntBigNumber,
  toLocaleString,
} from "@/utils/util"
import useGetSaveBid from "@/hooks/useGetSaveBid"
import { useToasts } from "react-toast-notifications"
import useGetAuction from "@/hooks/useGetAuction"
import useGetDecimalOPV from "@/hooks/useGetDecimalOPV"
import useGetOpvPrice from "@/hooks/useGetOpvPrice"
import { clearToken } from "../connectWallet/store/slice"
import { clearMyProfile } from "../myProfile/store/slice"
import { useHistory } from "react-router-dom"

const web3 = new Web3(
  Web3.givenProvider ||
    "wss://speedy-nodes-nyc.moralis.io/d682253c26212ac170504417/bsc/testnet/ws"
)

const ModalBidNow = () => {
  const { addToast } = useToasts()
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const history = useHistory()
  const inputRef = useRef<any>(null)
  const { modalBidNow } = useSelector((state: any) => state.modal)
  const [opvPrice] = useGetOpvPrice()

  const [price, setPrice] = useState<string>("0")
  const [priceInput, setPriceInput] = useState<number>(0)
  const [priceMinBid, setPriceMinBid] = useState<number>(0)
  const [balance, setBalance] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [isApprove, setIsApproved] = useState<boolean>(false)

  // contract
  const opvContract = useERC20Contract(OPV)
  const contractAuction = useAuctionContract()
  const decimalOPV = useGetDecimalOPV()

  const saveBid = useGetSaveBid()
  const [auction, getAuction] = useGetAuction()

  const getBalanceOpv = useCallback(
    (_account: any) => {
      if (decimalOPV && opvContract && _account) {
        opvContract?.balanceOf(_account).then(async (result: any) => {
          const balance = parseIntBigNumber(result._hex, decimalOPV)
          return setBalance(balance)
        })
      }
    },
    [decimalOPV, opvContract]
  )

  const handleCancel = useCallback(() => {
    if (!loading) {
      dispatch(
        setModalBidNow({
          toggle: false,
          data: {},
        })
      )
    }
  }, [dispatch, loading])

  const handleBuyNow = useCallback(() => {
    if (balance >= priceMinBid) {
      setLoading(true)

      if (opvContract && contractAuction) {
        let approve_amount =
          "115792089237316195423570985008687907853269984665640564039457584007913129639935"

        try {
          opvContract
            .allowance(account, AUCTION_CONTRACT)
            .then(async (balanceAllowance: any) => {
              let approve = true

              if (+balanceAllowance < +price) {
                approve = false
                await opvContract
                  .approve(AUCTION_CONTRACT, approve_amount)
                  .then(async (result: any) => {
                    if (result) {
                      const recipt: any = await getTransactionReceiptMined(
                        web3,
                        result.hash,
                        500
                      )

                      if (recipt.status === true) {
                        approve = true
                      }
                    }
                  })
              }

              if (approve) {
                const paramsBid = {
                  _tokenId: modalBidNow?.data?.nftId,
                  _amount: price,
                  _bidder: account,
                  _nft: modalBidNow?.data?.catalog?.contractAddress,
                }

                if (contractAuction) {
                  try {
                    contractAuction
                      .bid(
                        paramsBid._tokenId,
                        paramsBid._amount,
                        paramsBid._bidder,
                        paramsBid._nft,
                        {
                          value: 0,
                        }
                      )
                      .then(async (result: any) => {
                        if (result) {
                          // wait recipt
                          const recipt: any = await getTransactionReceiptMined(
                            web3,
                            result.hash,
                            500
                          )

                          if (recipt.status === true) {
                            // update item aution
                            getAuction(
                              modalBidNow?.data?.nftId,
                              modalBidNow?.data?.catalog?.contractAddress
                            )

                            // save transaction into api
                            saveBid(
                              {
                                nftId: modalBidNow?.data?.id,
                                transactionId:
                                  modalBidNow?.data?.currentListing?.[0]?.id,
                                transactionType: "BID",
                                listingPrice: priceInput,
                                transactionHash: result.hash,
                              },
                              () => {
                                addToast("successful bid", {
                                  appearance: "success",
                                })
                                setLoading(false)
                                handleCancel()
                              },
                              () => {
                                addToast("bid failed", {
                                  appearance: "error",
                                })
                                setLoading(false)
                              }
                            )
                          }
                        } else {
                          setLoading(false)
                        }
                      })
                      .catch((error: any) => {
                        const stringError = JSON.stringify(error)
                        console.error(stringError)
                        setLoading(false)
                      })
                  } catch (error) {
                    setLoading(false)
                    console.error("Bid", error)
                  }
                } else {
                  console.log(contractAuction)
                  addToast("contractAuction not null", {
                    appearance: "error",
                  })
                }
              }
            })
            .catch((error: any) => {
              console.error("Approve for bid", error)
              setLoading(false)
            })
        } catch (error) {
          setLoading(false)
          console.error("Approve for bid", error)
        }
      } else {
        setLoading(false)
        dispatch(clearToken())
        dispatch(clearMyProfile())
        localStorage.setItem("accessToken", "")
        localStorage.setItem("refreshToken", "")
        localStorage.setItem("_accountId", "")
        history.push("/connect-wallet")
        return
      }
    } else {
      addToast("You don't have enough money", {
        appearance: "info",
      })
    }
  }, [
    account,
    addToast,
    balance,
    contractAuction,
    dispatch,
    getAuction,
    handleCancel,
    history,
    modalBidNow?.data?.catalog?.contractAddress,
    modalBidNow?.data?.currentListing,
    modalBidNow?.data?.id,
    modalBidNow?.data?.nftId,
    opvContract,
    price,
    priceInput,
    priceMinBid,
    saveBid,
  ])

  useEffect(() => {
    if (account && priceInput) {
      const bgPrice = priceInput * 10 ** decimalOPV
      setPrice(toLocaleString(bgPrice))
    }
  }, [account, decimalOPV, priceInput])

  useEffect(() => {
    setPriceInput(
      roundWithCeil(
        +(
          (+auction?.endingPrice || +auction?.startingPrice) /
            10 ** decimalOPV +
          ((+auction?.endingPrice || +auction?.startingPrice) /
            10 ** decimalOPV) *
            (+auction?.stepBid / 10000)
        ),
        3
      )
    )

    setPriceMinBid(
      roundWithCeil(
        +(
          (+auction?.endingPrice || +auction?.startingPrice) /
            10 ** decimalOPV +
          ((+auction?.endingPrice || +auction?.startingPrice) /
            10 ** decimalOPV) *
            (+auction?.stepBid / 10000)
        ),
        3
      )
    )
  }, [
    auction?.endingPrice,
    auction?.startingPrice,
    auction?.stepBid,
    decimalOPV,
  ])

  useEffect(() => {
    if (modalBidNow?.data?.nftId) {
      getAuction(
        modalBidNow?.data?.nftId,
        modalBidNow?.data?.catalog?.contractAddress
      )
    }
  }, [
    getAuction,
    modalBidNow?.data?.catalog?.contractAddress,
    modalBidNow?.data?.nftId,
  ])

  useEffect(() => {
    getBalanceOpv(account)

    const interval = setInterval(() => {
      getBalanceOpv(account)
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [account, getBalanceOpv])

  useEffect(() => {
    if (opvContract) {
      opvContract
        .allowance(account, AUCTION_CONTRACT)
        .then(async (balanceAllowance: any) => {
          if (+balanceAllowance >= +price) {
            setIsApproved(true)
          } else {
            setIsApproved(false)
          }
        })
    }
  }, [account, opvContract, price])

  return (
    <Modal
      title="Place a bid"
      visible={modalBidNow?.toggle}
      onOk={handleBuyNow}
      onCancel={handleCancel}
      className="modal-place-bid"
      footer={false}
      style={{ bottom: 0 }}
    >
      <div className="approve-nft-info">
        <div className="approve-nft-info-image">
          <img
            src={
              modalBidNow?.data?.mediaUrl !== "Unknown"
                ? modalBidNow?.data?.mediaUrl
                : "https://d3aw5l8dgx938a.cloudfront.net/1691575404033-xb5izlsag4ktykyqtyvdyzrn.png"
            }
            alt={modalBidNow?.data?.name}
          />

          <div className="approve-nft-info-image-quantity">
            <p>
              <span>{modalBidNow?.data?.numberOfCopies}</span>
            </p>
          </div>
        </div>
        <div className="approve-nft-info-name">
          <p>{modalBidNow?.data?.name}</p>
          <p>
            {modalBidNow?.data?.catalog?.displayName
              ? modalBidNow?.data?.catalog?.displayName
              : modalBidNow?.data?.catalog?.name}
          </p>
        </div>
        <div className="approve-nft-info-price">
          <p>{formatAmount(modalBidNow?.data?.currentPrice)} OPV</p>
          <p>{formatAmount(modalBidNow?.data?.currentPrice * +opvPrice)}$</p>
        </div>
      </div>
      <div className="approve-nft-price">
        <div
          className="approve-nft-price-wrap-item cursor-text"
          onClick={() => {
            inputRef.current.focus()
          }}
        >
          <div className="approve-nft-price-item">
            <div>
              <InputNumber
                ref={inputRef}
                min={priceMinBid}
                onChange={(e: any) => {
                  setPriceInput(e || priceMinBid)
                }}
                max={balance >= priceMinBid ? balance : priceMinBid}
                value={priceInput}
                placeholder={`${priceMinBid || balance}`}
              />
            </div>
            <div>OPV</div>
          </div>
        </div>

        <div className="approve-nft-price-wrap-item">
          <div className="approve-nft-price-item">
            <div>(${formatAmount(balance * +opvPrice)})</div>
            <div>Balance: {formatAmount(balance)} OPV</div>
          </div>
        </div>
      </div>

      <div className="approve-nft-action">
        <Button
          className={`${balance < priceMinBid ? "disabled" : ""}`}
          loading={loading}
          onClick={handleBuyNow}
          disabled={loading}
        >
          {isApprove ? "Place a bid" : "Approve"}
        </Button>
      </div>
    </Modal>
  )
}

export default ModalBidNow
