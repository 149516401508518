import type { AddEthereumChainParameter } from "@web3-react/types"
import type { Connector } from "@web3-react/types"
import { MetaMask } from "@web3-react/metamask"
import { Network } from "@web3-react/network"
import { TrustWallet } from "@trustwallet/web3-react-trust-wallet"

type ChainConfig = {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation
}

interface BasicChainInformation {
  urls: string[]
  name: string
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter["nativeCurrency"]
  blockExplorerUrls: AddEthereumChainParameter["blockExplorerUrls"]
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export function getAddChainParameters(
  chainId: number
): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  } else {
    return chainId
  }
}

const BNB: AddEthereumChainParameter["nativeCurrency"] = {
  name: "BNB",
  symbol: "BNB",
  decimals: 18,
}

export const MAINNET_CHAINS: ChainConfig = {
  56: {
    urls: [
      "https://bsc-dataseed.bnbchain.org",
      "https://bsc-dataseed1.bnbchain.org",
      "https://bsc-dataseed2.bnbchain.org",
    ],
    name: "BNB Smart Chain",
    nativeCurrency: BNB,
    blockExplorerUrls: ["https://bscscan.com"],
  },
}

export const TESTNET_CHAINS: ChainConfig = {}

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
}

export const URLS: { [chainId: number]: string[] } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string[] }>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs
  }

  return accumulator
}, {})

export function getName(connector: Connector) {
  if (connector instanceof TrustWallet) return "TrustWallet"
  if (connector instanceof MetaMask) return "MetaMask"
  if (connector instanceof Network) return "Network"
  return "Unknown"
}
