import { usePushNotifications } from "@/contexts/PushNotificationProvider"
import { routes } from "@/router/constants"
import { PlusCircleFilled, ShoppingCartOutlined } from "@ant-design/icons"
import { Button, Dropdown, Menu } from "antd"
import { useCallback, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import ConnectWalletButton from "./buttons/ConnectWallet"
import { CartDropdown } from "./dropdowns/CartDropdown"
import NotificationPopup from "./dropdowns/NotificationPopup"
import PopupSearch from "./dropdowns/PopupSearch"
import ProfilePopup from "./dropdowns/ProfilePopup"
import useCheckNotification from "@/hooks/useCheckNotification"
import { useSelector } from "react-redux"
import { selectIsHasNotification } from "../../notification/store/slice"

interface IHeaderRightProps {
  setCloseNavbar: (closeNavbar: boolean) => void
  closeNavbar: boolean
  isSigned: boolean
}

const HeaderRight = ({
  closeNavbar,
  setCloseNavbar,
  isSigned,
}: IHeaderRightProps) => {
  const history = useHistory()
  const [visibleCart, setVisibleCart] = useState(true)
  const [visibleProfile, setVisibleProfile] = useState(true)
  const [visibleSearch, setVisibleSearch] = useState(true)
  const [visibleNotification, setVisibleNotification] = useState(true)

  const { hasNewNotification, setHasNewNotification } = usePushNotifications()
  const { getUnreadNotification } = useCheckNotification()
  const checkUnreadNotification = useSelector(selectIsHasNotification)

  const handleOpenCart = () => {
    setVisibleCart(false)
  }

  const handleOpenProfile = () => {
    setVisibleProfile(false)
  }

  const handleOpenNotification = useCallback(() => {
    setVisibleNotification(false)
    setHasNewNotification(false)
  }, [setHasNewNotification])

  const isMobile = useMemo(() => {
    return window?.innerWidth < 992
  }, [])

  const isConnect = useMemo(() => {
    return isSigned
  }, [isSigned])

  const handleClickMenu = useCallback(() => {
    setCloseNavbar(!closeNavbar)
  }, [closeNavbar, setCloseNavbar])

  const iconShopping = useMemo(
    () => (
      <>
        <ShoppingCartOutlined
          className="anticon-shopping"
          onClick={handleOpenCart}
        />
        <CartDropdown visible={visibleCart} setVisible={setVisibleCart} />
      </>
    ),
    [visibleCart]
  )

  const iconSearch = useMemo(
    () => (
      <>
        <img
          className="anticon-search"
          src={require("../../../../../assets/images/search.png")}
          alt=""
          onClick={() => setVisibleSearch((prev: boolean) => !prev)}
        />
        <PopupSearch visible={visibleSearch} setVisible={setVisibleSearch} />
      </>
    ),
    [visibleSearch]
  )

  const iconMenu = useMemo(
    () => (
      <img
        className="anticon-menu"
        src={require("../../../../../assets/images/menu.png")}
        alt=""
        onClick={handleClickMenu}
      />
    ),
    [handleClickMenu]
  )

  const iconUser = useMemo(
    () => (
      <>
        <img
          className="anticon-user"
          src={require("../../../../../assets/images/User.png")}
          alt=""
          onClick={handleOpenProfile}
        />
        <ProfilePopup visible={visibleProfile} setVisible={setVisibleProfile} />
      </>
    ),
    [visibleProfile]
  )

  const menuCreate = (
    <Menu>
      <Menu.Item onClick={() => handleMenuCreate?.(routes.CREATE_NFT)} key={1}>
        <p className="m-0 px-2 py-2 lg:min-w-[170px] text-text text-base font-bold">
          NFT
        </p>
      </Menu.Item>
      <Menu.Item
        onClick={() => handleMenuCreate?.(routes.CREATE_COLLECTION)}
        key={2}
      >
        <p className="m-0 px-2 py-2 lg:min-w-[170px] text-text text-base font-bold">
          Collection
        </p>
      </Menu.Item>
    </Menu>
  )

  const renderButtonCreate = useMemo(() => {
    return (
      <Dropdown
        overlay={menuCreate}
        placement="bottom"
        trigger={["hover", "click"]}
      >
        <Button className="btn-create" type="link" icon={<PlusCircleFilled />}>
          Create
        </Button>
      </Dropdown>
    )
  }, [])

  const iconNotification = useMemo(
    () => (
      <>
        <span className="relative">
          <img
            className="anticon-bell"
            src={require("../../../../../assets/images/bell-simple.png")}
            alt=""
            onClick={handleOpenNotification}
          />
          {checkUnreadNotification && (
            <span className="absolute top-1.5 right-1.5 w-2 h-2 rounded-full bg-red-500"></span>
          )}
        </span>
        {!visibleNotification && (
          <NotificationPopup
            visible={visibleNotification}
            setVisible={setVisibleNotification}
            refetchData={getUnreadNotification}
          />
        )}
      </>
    ),
    [
      handleOpenNotification,
      checkUnreadNotification,
      visibleNotification,
      getUnreadNotification,
    ]
  )

  const renderNoConnectWallet = useMemo(() => {
    if (isMobile) {
      return (
        <div className="no-connect-wallet-wrapper">
          {iconSearch}
          {iconMenu}
        </div>
      )
    }

    return (
      <div className="no-connect-wallet-wrapper">
        <div
          className="text-[#050505] font-bold cursor-pointer py-1 px-2"
          onClick={() => window.location.replace("https://swap.omarket.live")}
        >
          Swap
        </div>
        <ConnectWalletButton />
        {/* {iconShopping} */}
      </div>
    )
  }, [iconMenu, iconNotification, iconSearch, iconShopping, isMobile])

  const renderRewardButton = useMemo(() => {
    return (
      <a
        className="text-[#1da6dd] font-bold cursor-pointer py-1 px-2"
        // onClick={() => history.push(routes.REWARDS)}
        href="/reward"
      >
        Reward
      </a>
    )
  }, [])

  const renderConnectWallet = useMemo(() => {
    if (isMobile) {
      return (
        <div className="connect-wallet-wrapper">
          {iconSearch}
          {iconNotification}
          {iconUser}
          {/* {iconShopping} */}
          {iconMenu}
        </div>
      )
    }
    return (
      <div className="connect-wallet-wrapper">
        <div
          className="text-[#050505] font-bold cursor-pointer py-1 px-2"
          onClick={() => window.location.replace("https://swap.omarket.live")}
        >
          Swap
        </div>
        <div
          className="text-[#050505] font-bold cursor-pointer py-1 px-2"
          onClick={() => history.push(routes.VESTING)}
        >
          Vesting
        </div>
        {/* {renderRewardButton} */}
        {renderButtonCreate}
        {iconNotification}
        {iconUser}
        {/* {iconShopping} */}
      </div>
    )
  }, [iconMenu, iconNotification, iconSearch, iconShopping, iconUser, isMobile])

  function handleMenuCreate(path: string) {
    history.push({ pathname: path })
  }

  if (!isConnect) {
    return renderNoConnectWallet
  }

  return (
    <>
      <div className="header-right-wrapper">{renderConnectWallet}</div>
    </>
  )
}

export default HeaderRight
