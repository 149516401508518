import Popover from "@/components/common/popover"
import TabSelect from "@/components/common/tabSelect"
import { CloseOutlined } from "@ant-design/icons"
import { Card } from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import ButtonCustom from "../buttons/buttonCustom"
import CartFooter from "./CartFooter"
import "./notificationPopup.scss"
import { getIdUserView } from "@/utils/authentication"
import { useSelector } from "react-redux"
import { selectMyProfile } from "@/components/myProfile/store/slice"
import { post } from "@/network/axiosClient"
import notificationServices from "@/network/notifications/services"
import { convertDateTimeTolocal } from "@/utils/util"
import { useHistory } from "react-router-dom"
import useInfiniteScroll from "@/hooks/useInfiniteScroll"

interface NotificationPopupProps {
  children?: React.ReactNode
  items?: any[]
  visible: boolean
  setVisible: (visible: boolean) => void
  refetchData?: any
}

const NotificationPopup = ({
  visible,
  setVisible,
  refetchData,
}: NotificationPopupProps) => {
  const history = useHistory()
  const [tab, setTab] = useState("all")
  const myProfile = useSelector(selectMyProfile)
  const [allNotifications, setAllNotifications]: any = useState([])
  const [unreadNotifications, setUnreadAllNotifications]: any = useState([])
  const [pageTabAll, setPageTabAll] = useState<number>(1)
  const [pageTabUnread, setPageTabUnread] = useState<number>(1)
  const [hasMoreAll, setHasMoreAll] = useState<boolean>(true)
  const [hasMoreUnread, setHasMoreUnread] = useState<boolean>(true)

  const fetchNotification = async () => {
    let res: any = null
    if (tab === "all" && hasMoreAll) {
      res = await notificationServices.getAllNotifications({
        skip: (pageTabAll - 1) * 10,
        limit: 10,
        sort: { field: "created_at", order: -1 },
        filter: [{ name: "user", value: myProfile?.user?.id, operator: "eq" }],
      })

      if (res?.data?.data?.docs?.length) {
        setAllNotifications((val: any) => val.concat(res.data.data.docs))
        setPageTabAll((prevPage) => prevPage + 1)
      } else setHasMoreAll(false)
    } else if (tab === "unread" && hasMoreUnread) {
      res = await notificationServices.getAllNotifications({
        skip: (pageTabUnread - 1) * 10,
        limit: 10,
        sort: { field: "created_at", order: -1 },
        filter: [
          { name: "user", value: myProfile?.user?.id, operator: "eq" },
          { name: "isRead", value: false, operator: "eq" },
        ],
      })

      if (res?.data?.data?.docs?.length) {
        setUnreadAllNotifications((val: any) => val.concat(res.data.data.docs))
        setPageTabUnread((prevPage) => prevPage + 1)
      } else setHasMoreUnread(false)
    }
  }

  const fetchData = async () => {
    if (loading) return

    setLoading(true)
    try {
      const response = await fetchNotification()
    } catch (error) {
      console.error("An error occurred while fetching data:", error)
    } finally {
      setLoading(false)
    }
  }

  const { containerRef, loading, setLoading } = useInfiniteScroll(fetchData)

  useEffect(() => {
    if (
      ((tab === "all" && !allNotifications.length) ||
        (tab === "unread" && !unreadNotifications.length)) &&
      myProfile?.user?.id
    )
      fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotifications, unreadNotifications, myProfile?.user?.id, tab])

  useEffect(() => {
    if (!visible) {
      document.body.style.overflow = "hidden"
    }
  }, [visible])

  const handleCloseCart = useCallback(() => {
    setVisible(true)
    document.body.style.overflow = "unset"
  }, [setVisible])

  const renderCartFooter = useCallback(() => {
    return (
      <CartFooter>
        <ButtonCustom
          type="primary"
          handleClick={() => {
            handleCloseCart()
            history.push("/notification")
          }}
          block
        >
          See all notification
        </ButtonCustom>
      </CartFooter>
    )
  }, [])

  const renderDropdownContent = useMemo(() => {
    return (
      <Card
        className={`card-wrapper ${
          visible ? "close" : "open"
        } flex flex-col pb-[100px]`}
        title={<span className="font-bold text-[24px]">Notification</span>}
        extra={[<CloseOutlined onClick={handleCloseCart} />]}
        bodyStyle={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <div className="h-[calc(100%-70px)] overflow-hidden mr-[-1.5em] pr-[1.5em] flex flex-col">
          <div className="flex justify-between items-center">
            <TabSelect
              tabs={[
                {
                  key: "all",
                  title: "All",
                  onClick: () => setTab("all"),
                },
                {
                  key: "unread",
                  title: "Unread",
                  onClick: () => setTab("unread"),
                },
              ]}
            />
            <div>
              <Popover
                items={[
                  {
                    label: "Mark all as read",
                    icon: require("@/assets/images/notification/check.png"),
                    onClick: async () => {
                      await notificationServices.postNotificationsReadAll()
                      setAllNotifications([])
                      setUnreadAllNotifications([])
                      setPageTabAll(1)
                      setPageTabUnread(1)
                      setHasMoreAll(true)
                      setHasMoreUnread(true)
                      refetchData()
                    },
                  },
                  {
                    label: "Notification setting",
                    icon: require("@/assets/images/notification/gear.png"),
                    onClick: () => {
                      history.push("/setting")
                      handleCloseCart()
                    },
                  },
                ]}
              >
                <button className="text-[24px]">...</button>
              </Popover>
            </div>
          </div>
          <div
            ref={containerRef}
            className="mt-4 flex-1 overflow-auto -mr-6 pr-6"
          >
            <div className="space-y-2">
              {(tab === "all" ? allNotifications : unreadNotifications)?.map(
                (item: any) => {
                  return (
                    <div className="flex items-center" key={item?.id}>
                      <div className="flex-1 flex items-center space-x-4">
                        <div>
                          <img
                            src={require("@/assets/images/default-avatar.png")}
                            width={40}
                            alt=""
                          />
                        </div>
                        <div>
                          <p className="m-0 text-[12px]">{item?.title}</p>
                          <p className="m-0 text-[10px] text-[#999999]">
                            {convertDateTimeTolocal(item?.created_at)}
                          </p>
                        </div>
                      </div>
                      {!item?.isRead && (
                        <div className="text-[#1DA6DD] text-xl">&#x2022;</div>
                      )}
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
        {renderCartFooter()}
      </Card>
    )
  }, [
    visible,
    handleCloseCart,
    containerRef,
    tab,
    allNotifications,
    unreadNotifications,
    renderCartFooter,
    history,
  ])

  return (
    <>
      <div
        className={`overlay  ${!visible ? "open" : "close"}`}
        onClick={handleCloseCart}
      />
      <div
        className={`cart-notification-dropdown-wrapper ${
          !visible ? "open" : "close"
        }`}
      >
        {renderDropdownContent}
      </div>
    </>
  )
}

export default NotificationPopup
