import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { injected } from "../utils/connectors"

export default function useEagerConnect() {
  const {
    connector: { activate },
    isActive: active,
  } = useWeb3React()

  const [tried, setTried] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then(async (isAuthorized) => {
      if (isAuthorized) {
        try {
          await activate(injected, undefined, true)
        } catch (error) {
          setTried(true)
        }
      } else {
        setTried(true)
      }
    })
  }, [activate])

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  return tried
}
