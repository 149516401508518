import { lazy, Suspense } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { routes } from "./constants"

import TheLoadingSpinner from "../components/common/loading"

const ConnectWallet = lazy(
  () => import("../components/connectWallet/connectWallet")
)
const ChangeChain = lazy(() => import("../components/changeChain/changeChain"))

const AuthRoutes = () => {
  return (
    <Suspense fallback={<TheLoadingSpinner isLoading={true} />}>
      <Switch>
        <Route path={routes.LOGIN} component={ConnectWallet} exact />
        <Route path={routes.CHANGE_CHAIN} component={ChangeChain} exact />

        <Redirect from="*" to={routes.LOGIN} />
      </Switch>
    </Suspense>
  )
}

export default AuthRoutes
