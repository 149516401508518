import { useCallback, useEffect, useState } from "react"
import useGetDecimalOPV from "./useGetDecimalOPV"
import useERC20Contract from "./useERC20Contract"
import { OPV } from "@/utils/constant"
import { parseIntBigNumber } from "@/utils/util"
import { selectWalletAddress } from "@/components/connectWallet/store/slice"
import { useSelector } from "react-redux"

const useGetBalanceOpv = () => {
  const decimalOPV = useGetDecimalOPV()
  const opvContract = useERC20Contract(OPV)
  const account = useSelector(selectWalletAddress)
  const [balance, setBalance] = useState<number>(0)

  const getBalanceOpv = useCallback(
    (_account: string) => {
      if (decimalOPV && opvContract && _account) {
        opvContract?.balanceOf(_account).then(async (result: any) => {
          const balance = parseIntBigNumber(result._hex, decimalOPV)
          return setBalance(balance)
        })
      }
    },
    [decimalOPV, opvContract]
  )

  useEffect(() => {
    getBalanceOpv(account)

    const interval = setInterval(() => {
      getBalanceOpv(account)
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [account, getBalanceOpv])

  return [balance, getBalanceOpv]
}

export default useGetBalanceOpv
