import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./app/rootStore"
import "./App.css"
import Routes from "./router/Routes"
import { PushNotificationProvider } from "./contexts/PushNotificationProvider"
// import { useIdleTimer } from 'react-idle-timer'

const App = () => {
  // const handleOnIdle = () => {
  //   window.alert("You should reload page")
  //   window.location.reload()
  // }

  // useIdleTimer({
  //   timeout: 1000 * 60 * 10,
  //   onIdle: handleOnIdle,
  // })

  return (
    <Provider store={store}>
      <PushNotificationProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </PushNotificationProvider>
    </Provider>
  )
}

export default App
