import { Button } from "antd"
import "./groupCategories.scss"
import { useMemo, useState } from "react"
import { AxiosResponse } from "axios"
import { useHistory } from "react-router-dom"
import { useLayoutContext } from "@/components/common/menu/Menu"
import { routes } from "@/router/constants"

interface ICategory {
  deleted_at?: string | null
  name?: string
  status?: string
  created_at?: string
  updated_at?: string
  id?: string
}

export interface ICategoryResponse extends AxiosResponse {
  data: {
    data: { docs: ICategory[] }
    hasError: number
    message: string
    success?: boolean
  }
}

const GroupCategoriesButton = () => {
  const { categories } = useLayoutContext()
  const history = useHistory()
  const [active, setIsActive] = useState("All")

  const groupButton = useMemo(() => {
    const _categories = [
      {
        key: "all",
        title: "All",
        className: "btn-text",
      },
    ]
    if (!categories?.length) {
      return _categories
    }
    const data = categories?.map((_category) => {
      return {
        key: _category?.id,
        title: _category?.name,
        className: "btn-text",
      }
    })
    return [..._categories, ...data]
  }, [categories?.length])

  function handleClick(e: string, id: string) {
    setIsActive(e)
    if (e === "All") {
      history.push({ pathname: routes.HOMEPAGE })
    } else {
      history.push({ pathname: `/category/${e}` })
    }
  }

  return (
    <div className="group-categories-wrapper">
      {groupButton.map((_button) => {
        return (
          <Button
            className={`${_button?.className ?? ""} ${
              active === _button?.title ? "active" : ""
            }`}
            type="link"
            onClick={() =>
              handleClick(_button?.title ?? "", _button?.key ?? "")
            }
            key={_button?.key}
          >
            {_button?.title ?? ""}
          </Button>
        )
      })}
    </div>
  )
}
export default GroupCategoriesButton
