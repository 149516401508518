import ChartBarIcon from "@/components/icons/ChartBarIcon"
import CompassIcon from "@/components/icons/CompassIcon"
import FilePlusIcon from "@/components/icons/FilePlusIcon"
import GlobeIcon from "@/components/icons/GlobeIcon"
import HomeIcon from "@/components/icons/HomeIcon"
import { routes } from "@/router/constants"
import {
  EuroCircleOutlined,
  GiftOutlined,
  SwapOutlined,
} from "@ant-design/icons"

interface NavLinkItem {
  title: string
  icon?: React.FC
  path?: string
  redirect?: string
}

export interface NavLink extends NavLinkItem {
  subMenu?: NavLinkItem[]
}
export type VerticalNavItemsType = NavLink[]

const navigation = (): VerticalNavItemsType => [
  {
    title: "Home",
    icon: HomeIcon,
    path: "/",
  },
  // {
  //   title: "Loyalty",
  //   icon: GlobeIcon,
  //   path: "/loyalty",
  // },
  {
    title: "Explore",
    icon: CompassIcon,
    path: "/explore",
  },
  {
    title: "Swap",
    icon: SwapOutlined,
    redirect: "https://swap.omarket.live",
  },
  {
    title: "Vesting",
    icon: EuroCircleOutlined,
    path: "/vesting",
  },
  {
    title: "Reward",
    icon: GiftOutlined,
    path: "/reward",
  },
  {
    title: "Stats",
    icon: ChartBarIcon,
    subMenu: [
      {
        title: "Ranking",
        path: "/ranking",
      },
      {
        title: "Activities",
        path: "/activities",
      },
    ],
  },
  {
    title: "Create",
    icon: FilePlusIcon,
    subMenu: [
      {
        title: "NFT",
        path: routes.CREATE_NFT,
      },
      {
        title: "Collection",
        path: routes.CREATE_COLLECTION,
      },
    ],
  },
]

export default navigation
