import { useEffect, useState } from 'react';
import axios from 'axios';

interface TokenPrice {
  [tokenId: string]: {
    usd: number;
    last_updated: string;
  };
}

interface CoingeckoToken {
  id: string;
  symbol: string;
}

let tokenList: CoingeckoToken[] = []; // Biến state toàn cục để lưu trữ danh sách token

const useTokenPrice = (symbol: string) => {
  const [price, setPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTokenPrice = async (tokenID: string) => {
      try {
        const tokenPriceResponse = await axios.get<TokenPrice>(
          `https://api.coingecko.com/api/v3/simple/price?ids=${tokenID}&vs_currencies=usd`
        );
        setPrice(tokenPriceResponse.data[tokenID].usd);
      } catch (error) {
        console.error('Error fetching token price:', error);
        setPrice(null);
      } finally {
        setLoading(false);
      }
    };

    const fetchTokenList = async () => {
      try {
        // Lấy danh sách token từ Coingecko nếu danh sách chưa có
        if (tokenList.length === 0) {
          const response = await axios.get<CoingeckoToken[]>(
            'https://api.coingecko.com/api/v3/coins/list'
          );
          tokenList = response.data;
        }

        // Tìm kiếm thông tin cho token hiện tại trong danh sách đã lấy
        const selectedToken = tokenList.find((token) => token.symbol === symbol.toLowerCase());
        if (selectedToken) {
          fetchTokenPrice(selectedToken.id);
        } else {
          setPrice(null);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching token list:', error);
        setPrice(null);
        setLoading(false);
      }
    };

    fetchTokenList();
  }, [symbol]);

  return { price: price || 0, loading };
};

export default useTokenPrice;
