import { ethers } from "ethers"
import { LINK_OPEN_METAMASK, supportChain } from "utils/constant"

import { useCallback, useEffect, useRef, useState } from "react"
import { notification } from "antd"
import { message } from "antd"

import { useWeb3React } from "@web3-react/core"
// import { InjectedConnector } from "wagmi/connectors/injected"
import { get, post } from "network/axiosClient"

import { LoginResponse } from "components/connectWallet/store/type"
import { useDispatch } from "react-redux"
import { setAuthentication } from "components/connectWallet/store/slice"
import { uuid } from "uuidv4"
import moment from "moment"
import { getAuthorizationHeader } from "@/utils/authentication"
// import { useAccount, useConnect } from "wagmi"
// import { useSignMessage } from "@/packages/wagmi/src/hooks/useSignMessage"

import { metaMask, hookMetamask } from "utils/connectors"
import { getAddChainParameters, getName } from "@/utils/chains"

const {
	useChainId,
	useAccounts,
	useIsActivating,
	useIsActive,
	useProvider,
	useENSNames,
} = hookMetamask

// const connector = new InjectedConnector()

const useConnectWallet = () => {
	const dispatch = useDispatch()
	// const signMessageAsync = useSignMessage()

	const [walletAddress, setWalletAddress] = useState<String | null>(null)
	const [isConnectMetamask, setIsConnectMetamask] = useState<boolean>(false)
	const [openSignWallet, setOpenSignWallet] = useState(false)
	const [balanceAccount, setBalanceAccount] = useState(0)

	const { account: address, connector, isActive, provider } = useWeb3React()

	console.log(`Priority Connector is: ${getName(connector)}`)

	const currentAccount = useRef<string>("")

	const [api, contextHolder] = notification.useNotification()

	useEffect(() => {
		const accessTokenStorage = localStorage.getItem("accessToken")
		const walletAddressStorage = localStorage.getItem("walletAddress")

		if (accessTokenStorage && walletAddressStorage) {
			setWalletAddress(walletAddressStorage)
		}
	}, [])

	const callSign = useCallback(async () => {
		const signer = provider?.getSigner()
		console.log("call Sign", signer)
		try {
			const generateUUID = uuid()
			const message = `Welcome to Monbase NFT! Click to sign in and accept the Monbase NFT Terms of Service: https://nft.monbase.com. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 24 hours. Wallet address: ${address?.toLowerCase()}. Nonce: ${generateUUID}`
			const signature = await signer?.signMessage(message)
			console.log("signature", signature)
			// const signature = await signMessageAsync({
			//   message: message,
			// })
			const verify = ethers.utils.verifyMessage(message, signature || "")
			localStorage.setItem("provider", "metamask")
			if (verify) {
				await callLogin(address || "", signature || "", generateUUID)
			}

			// callback?.()
		} catch (e) {
			console.log("sign Error", e)
			// message.error(e?.message, 3)
			// setIsConnectMetamask(false)
			// callback?.()
		}
	}, [])

	useEffect(() => {
		const signer = provider?.getSigner()
		if (isActive && address && signer && !walletAddress) {
			callSign()
		}
	}, [isActive, address, provider, walletAddress, callSign])

	const callLogin = async (
		walletAddress: string,
		signature: string,
		generateUUID: string
	) => {
		try {
			let params = {
				walletAddress: walletAddress,
				deadline: moment().unix() + 5 * 60 * 1000,
				sig: signature,
				uuid: generateUUID,
			}

			const res: LoginResponse = await post("/auth/sign-in", params)
			if (res?.data?.success) {
				localStorage.setItem("accessToken", res?.data?.data?.access_token || "")
				localStorage.setItem(
					"refreshToken",
					res?.data?.data?.refresh_token || ""
				)
				localStorage.setItem("walletAddress", walletAddress)

				setWalletAddress(walletAddress)
				dispatch(
					setAuthentication({
						accessToken: res?.data?.data?.access_token,
						refreshToken: res?.data?.data?.refresh_token,
						walletAddress,
					})
				)

				await get("/moralis/collection-nfts", {
					headers: getAuthorizationHeader(),
				})

				message.success("Login Success", 3)
			}
		} catch (e) {
			message.error(e?.message)
		}
	}

	const signAndAccept = async (callback?: () => void) => {
		if (!window.ethereum) {
			return window.open(LINK_OPEN_METAMASK)
		}

		if (!isActive) {
			// const resultConnect = await connectAsync({ connector })
			try {
				await connector.activate(getAddChainParameters(Number(supportChain)))

				// try {
				//   const generateUUID = uuid()
				//   const message = `Welcome to OMarket! Click to sign in and accept the OMarket Terms of Service: https://omarket.live. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 24 hours. Wallet address: ${resultConnect?.account.toLowerCase()}. Nonce: ${generateUUID}`
				//   const signature = await signMessageAsync({
				//     message: message,
				//   })
				//   const verify = ethers.utils.verifyMessage(message, signature || "")
				//   localStorage.setItem("provider", "metamask")
				//   if (verify) {
				//     await callLogin(
				//       resultConnect?.account,
				//       signature || "",
				//       generateUUID
				//     )
				//   }

				//   callback?.()
				// } catch (e) {
				//   message.error(e?.message, 3)
				//   setIsConnectMetamask(false)
				//   callback?.()
				// }
			} catch (error) {
				console.log("activate error", error)
			}
		} else {
			try {
				const generateUUID = uuid()
				const message = `Welcome to Monbase NFT! Click to sign in and accept the Monbase NFT Terms of Service: https://nft.monbase.com. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 24 hours. Wallet address: ${address?.toLowerCase()}. Nonce: ${generateUUID}`

				const signer = provider?.getSigner()
				const signature = await signer?.signMessage(message)
				// const signature = await signMessageAsync({
				//   message: message,
				// })
				const verify = ethers.utils.verifyMessage(message, signature || "")
				localStorage.setItem("provider", "metamask")
				if (verify) {
					await callLogin(address as string, signature || "", generateUUID)
				}

				callback?.()
			} catch (e) {
				message.error(e?.message, 3)
				setIsConnectMetamask(false)
				callback?.()
			}
		}
	}

	function checkSignConnectWallet() {
		const walletAddressStorage = localStorage.getItem("walletAddress")
		const accessTokenStorage = localStorage.getItem("accessToken")

		if (walletAddressStorage && !accessTokenStorage) {
			setWalletAddress(walletAddressStorage)
			return true
		}
		return false
	}

	function checkAccessToken() {
		const accessTokenStorage = localStorage.getItem("accessToken")

		if (accessTokenStorage) {
			return true
		}
		return false
	}

	function checkMetamaskProvider() {
		const providerStorage = localStorage.getItem("provider")
		if (providerStorage === "metamask") {
			return true
		}
		return false
	}

	const handleConnectWallet = async (callback?: () => void) => {
		if (!window.ethereum) {
			return window.open(LINK_OPEN_METAMASK)
		}

		try {
			setIsConnectMetamask(true)
			const walletAddressStorage = localStorage.getItem("walletAddress")

			if (!walletAddressStorage || walletAddressStorage !== walletAddress) {
				setOpenSignWallet(true)
			} else {
				signAndAccept(callback)
			}
			callback?.()

			setIsConnectMetamask(false)
		} catch (e) {
			message.error(e?.message, 3)
			setIsConnectMetamask(false)
			callback?.()
		}
	}

	return {
		handleConnectWallet,

		walletAddress,
		setWalletAddress,
		currentAccount,
		contextHolder,
		isConnectMetamask,
		checkSignConnectWallet,
		openSignWallet,
		setOpenSignWallet,
		checkMetamaskProvider,
		account: address,
		balanceAccount,
		deactivate: connector.deactivate,
		signAndAccept,
		checkAccessToken,
		setIsConnectMetamask,
	}
}

export default useConnectWallet
