export const supportChain = process?.env?.REACT_APP_SUPPORT_CHAIN || 56
export const MARKET_ADDRESS = process?.env?.REACT_APP_MARKET_ADDRESS!
export const MARKET_ADDRESS_NEW = process?.env?.REACT_APP_MARKET_ADDRESS_NEW!
export const MARKET_FEATURE_ADDRESS =
	process?.env?.REACT_APP_MARKET_FEATURE_ADDRESS!
export const OPV_ADDRESS = process?.env?.REACT_APP_MBC_ADDRESS!
export const FACTORY_ADDRESS = process?.env?.REACT_APP_FACTORY_ADDRESS!
export const MULTIL_FACTORY_ADDRESS =
	process?.env?.REACT_APP_MULTIL_FACTORY_ADDRESS!
export const AIRDROP_ADDRESS = process?.env?.REACT_APP_AIRDROP_ADDRESS!
export const TRANSFER_ADDRESS = process?.env?.REACT_APP_TRANSFER_ADDRESS!
export const EVENT_ADDRESS = process?.env?.REACT_APP_EVENT!
export const AUCTION_ADDRESS = process?.env?.REACT_APP_AUCTION_ADDRESS!
export const AUCTION_MULTIL_ADDRESS =
	process?.env?.REACT_APP_AUCTION_MULTIL_ADDRESS!
export const AUCTION_MULTIL_SEC_ADDRESS =
	process?.env?.REACT_APP_AUCTION_MULTIL_SEC_ADDRESS!
export const MARKETPLACE_ADDRESS = process?.env?.REACT_APP_MARKETPLACE_ADDRESS!

export const EMPTY_VALUE = "--"
export const CURRENCY = "MBC"
export const LINK_OPEN_METAMASK = process?.env?.REACT_APP_LINK_OPEN_METAMASK
export const LINK_OPEN_TRUST = process?.env?.REACT_APP_LINK_OPEN_TRUST
export const ADDRESS_CONTRACT_SWAP =
	process.env.REACT_APP_SWAP_TOKEN_ADDRESS || ""
export const OPV = process.env.REACT_APP_MBC_ADDRESS || ""

export const CREATE_COLLECTION_OWN_CONTRACT = process?.env
	?.REACT_APP_CREATE_COLLECTION_OWN_CONTRACT as string
export const COLLECTION_FACTORY = process?.env
	?.REACT_APP_COLLECTION_FACTORY as string

export const AUCTION_CONTRACT = process.env.REACT_APP_AUCTION_CONTRACT as string
export const PROVIDER_NETWORK_URL = process.env.REACT_APP_RPC_URL as string
export const OPV_PRICE_CONTRACT = process.env.REACT_APP_OPV_PRICE as string

export const OPV_VESTING_CONTRACT_ADDRESS = process.env
	.REACT_APP_CONTRACT_VESTING as string
export const OPV_VESTING_GRAPH_ENDPOINT = process.env
	.REACT_APP_GRAPH_VESTING as string
