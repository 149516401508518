import { lazy, Suspense } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { routes } from "./constants"

import TheLoadingSpinner from "../components/common/loading"

const MyProfile = lazy(() => import("../containers/myProfile"))
const EditProfile = lazy(() => import("../containers/editProfile"))
const CreateNFT = lazy(() => import("../containers/createNFT"))
const CreateCollection = lazy(() => import("../containers/createCollection"))
const EditCollection = lazy(() => import("../containers/editCollection"))

const UpdateCollection = lazy(() => import("../containers/updateCollection"))
const SwapToken = lazy(() => import("../containers/swapToken"))
const Airdrop = lazy(() => import("../containers/airdrop"))
const SettingsLayout = lazy(() => import("../containers/settingsLayout"))
const ReferralLayout = lazy(() => import("../containers/referralLayout"))
const WatchList = lazy(() => import("../containers/watchList"))
const EditNFT = lazy(() => import("../containers/editNFT"))
const ListOnSale = lazy(() => import("../containers/listOnSale"))
const Vesting = lazy(() => import("../containers/vesting"))
const VestingHistory = lazy(() => import("../containers/vestingHistory"))

const ProtectedRoutes = () => {
  return (
    <Suspense fallback={<TheLoadingSpinner isLoading={true} />}>
      <Switch>
        <Route path={routes.MY_PROFILE} component={MyProfile} exact />
        <Route path={routes.EDIT_PROFILE} component={EditProfile} exact />
        <Route path={routes.CREATE_NFT} component={CreateNFT} exact />
        <Route path={routes.EDIT_NFT} component={EditNFT} exact />
        <Route path={routes.AIRDROP} component={Airdrop} exact />
        <Route path={routes.SETTINGS_LAYOUT} component={SettingsLayout} exact />
        <Route path={routes.REFERRAL_LAYOUT} component={ReferralLayout} exact />
        <Route path={routes.WATCH_LIST} component={WatchList} exact />
        <Route
          path={routes.CREATE_COLLECTION}
          component={CreateCollection}
          exact
        />
        <Route path={routes.EDIT_COLLECTION} component={EditCollection} exact />
        <Route
          path={routes.UPDATE_COLLECTION}
          component={UpdateCollection}
          exact
        />
        <Route path={routes.SWAP} component={SwapToken} exact />
        <Route path={routes.LIST_ON_SALE} component={ListOnSale} exact />
        <Route path={routes.VESTING} component={Vesting} exact />
        <Route path={routes.VESTING_HISTORY} component={VestingHistory} exact />

        <Redirect from="*" to={routes.HOMEPAGE} />
      </Switch>
    </Suspense>
  )
}

export default ProtectedRoutes
