import { useEffect, useState, useRef } from "react"
import { useWeb3React } from "@web3-react/core"
// import { Web3Provider } from "@ethersproject/providers"
// import { Web3ReactContextInterface } from "@web3-react/core/dist/types"
import { supportChain } from "@/utils/constant"
import { StaticJsonRpcProvider } from "@ethersproject/providers"
import getRpcUrl from "./getRpcUrl"
import { useSelector } from "react-redux"
import { selectMyProfile } from "@/components/myProfile/store/slice"

const RPC_URL = getRpcUrl()
const simpleRpcProvider = new StaticJsonRpcProvider(RPC_URL)

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = () => {
  // const { library, chainId, account, ...web3React } = useWeb3React()
  const { connector, chainId, account, ...web3React } = useWeb3React()
  const refEth = useRef(connector)
  const [provider, setProvider] = useState(connector || simpleRpcProvider)
  const myProfile = useSelector(selectMyProfile)

  useEffect(() => {
    if (connector !== refEth.current) {
      setProvider(connector || simpleRpcProvider)
      refEth.current = connector
    }
  }, [connector])

  return {
    library: provider,
    account: account ?? myProfile?.user?.walletAddress,
    chainId: chainId ?? parseInt(`${supportChain}`, 10),
    ...web3React,
  }
}

export default useActiveWeb3React
