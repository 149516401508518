import { ReactNode, useCallback, useMemo } from "react"
import "./verticalLayout.scss"
import { SideBar } from "./sideBar"
import { useLocation, useParams, useRouteMatch } from "react-router-dom"
import { routes } from "@/router/constants"

export interface VerticalLayoutProps {
	children: ReactNode
	setCloseNavbar: (closeNavbar: boolean) => void
	closeNavbar: boolean
}

export interface IParamPath {
	id: string
}

const VerticalLayout = ({
	children,
	closeNavbar,
	setCloseNavbar,
}: VerticalLayoutProps) => {
	const fullPage = [
		routes.PROFILE_VIEW,
		routes.MY_PROFILE,
		routes.DETAIL_COLLECTION,
		routes.LIST_ON_SALE,
		routes.RANKING,
	]

	const isFullPage = useRouteMatch(fullPage)

	return (
		<>
			<div
				className={
					isFullPage
						? "vertical-layout-full-wrapper"
						: "vertical-layout-wrapper"
				}
			>
				<SideBar closeNavbar={closeNavbar} setCloseNavbar={setCloseNavbar}>
					{children}
				</SideBar>
			</div>

			<div className="footer-copy-right">
				<div className="live-footer-container">
					<p>
						Copyright 2023 Monbase Global LLC. <span>All rights reserved</span>
					</p>
					<p>We use cookies for better service.</p>
				</div>
			</div>
		</>
	)
}

export default VerticalLayout
