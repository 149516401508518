import { Modal, Typography } from "antd"

import { useLayoutContext } from "../../../Menu"
import "./connectWallet.scss"

import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setModalConnectWallet } from "@/app/modal/actions"
import { LINK_OPEN_METAMASK, LINK_OPEN_TRUST } from "@/utils/constant"
interface ConnectWalletModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}
const ConnectWalletModal = ({
  open = false,
  setOpen,
}: ConnectWalletModalProps) => {
  const dispatch = useDispatch()
  const { handleConnectWallet, isConnectMetamask, setIsConnectMetamask } =
    useLayoutContext()
  const { modalConnectWallet } = useSelector((state: any) => state.modal)

  const isDisable = useMemo(() => {
    return isConnectMetamask
  }, [isConnectMetamask])

  const handleOk = () => {
    setOpen(false)
    dispatch(
      setModalConnectWallet({
        toggle: false,
      })
    )
  }
  const handleCancel = () => {
    setOpen(false)
    setIsConnectMetamask?.(false)
    dispatch(
      setModalConnectWallet({
        toggle: false,
      })
    )
  }

  const handleSelectWalletMetamask = () => {
    if (isDisable) return
    if (!window.ethereum) {
      return window.open(LINK_OPEN_METAMASK)
    }
    handleConnectWallet?.(handleOk)
  }

  const handleSelectWalletTrust = () => {
    if (isDisable) return
    if (!window.ethereum) {
      return window.open(LINK_OPEN_TRUST)
    }
    handleConnectWallet?.(handleOk)
  }

  return (
    <>
      <Modal
        title="Connect wallet"
        className="modal-connect-wallet-wrapper"
        visible={open || modalConnectWallet?.toggle}
        onOk={handleOk}
        onCancel={handleCancel}
        width={526}
        footer={<></>}
      >
        <p>
          If you don’t have a wallet, you can select a provider and create one
          now{" "}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",

            gap: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "8px 24px",
              gap: "16px",
              alignItems: "center",
              borderRadius: "8px",
              border: "1px solid var(--grayscale-light-90, #E6E6E6)",
              cursor: isDisable ? "wait" : "pointer",
            }}
            onClick={handleSelectWalletMetamask}
          >
            <img
              src={require("assets/images/MetaMask_Fox 2.png")}
              height={40}
              width={40}
              alt=""
            />
            <Typography>Metamask</Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "8px 24px",
              gap: "16px",
              alignItems: "center",
              borderRadius: "8px",
              border: "1px solid var(--grayscale-light-90, #E6E6E6)",
              cursor: "pointer",
            }}
            onClick={handleSelectWalletTrust}
          >
            <img
              src={require("assets/images/Trust.png")}
              height={40}
              width={40}
              alt=""
            />
            <Typography>Trust</Typography>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ConnectWalletModal
